import Constants from 'constants/index';
import { useDeviceInfo } from 'util/device';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider, Surface } from 'cfa-react-components';

const StickyFilterCard = ({
  top,
  'data-testid': dataTestId,
  className,
  children,
}) => {
  const { isDesktop } = useDeviceInfo();

  return (
    <FilterCard
      $isDesktop={isDesktop}
      $top={top}
      className={className}
      data-testid={dataTestId}
      elevation={1}
      radii="sm"
      variant="outlined"
    >
      {/** We check to see if there are multiple children */}
      {children.length !== undefined ? (
        children.map((child, index) => {
          return (
            <div key={index}>
              {child}
              {index !== children.length - 1 && child && (
                <SectionDivider variant="fullLength" />
              )}
            </div>
          );
        })
      ) : (
        <div>{children}</div>
      )}
    </FilterCard>
  );
};

const FilterCard = styled(Surface)`
  position: sticky;
  top: ${({ $top, $isDesktop }) =>
    $isDesktop ? $top : `calc(${$top} + ${Constants.HEIGHT.MOBILE_TOP_NAV})`};
  // this calc is to prevent the card from overflowing and also going over the topnav
  max-height: ${({ $top, $isDesktop }) =>
    $isDesktop
      ? `calc(100vh - ${$top} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.BUILD_PLANS_HEADER} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`
      : `calc(100vh - ${$top} - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.BUILD_PLANS_HEADER} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`};
  z-index: 4;
  width: 270px;
  min-width: 270px;
  flex-shrink: 1;
  padding: 16px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px 0;
  overflow-y: auto;
`;

const SectionDivider = styled(Divider)`
  margin: 16px 0;
`;

StickyFilterCard.propTypes = {
  top: PropTypes.string,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

StickyFilterCard.defaultProps = {
  top: '103px',
  className: '',
  'data-testid': 'StickyFilterCard',
};

export default StickyFilterCard;
