import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import Constants from 'constants/index';
import { getDueDateColor, getDueDateString } from 'util/dueDate';
import {
  getHrsFromTotalMins,
  getMinsFromTotalMins,
  getTimeSpentOnPlanForTeamMember,
} from 'util/time';
import PropTypes from 'prop-types';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import { Ribbon } from 'icons';
import styled from 'styled-components';
import PlanCardIcon from 'components/PlanCard/PlanCardComponents/PlanCardIcon';
import PlanCardTag from 'components/PlanCard/PlanCardComponents/PlanCardTag';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';

const ReportsTeamMemberPlanCard = ({
  className,
  'data-testid': dataTestId,
  plan,
  status,
  teamMemberId,
}) => {
  const { t } = useTranslation();
  const isComplete = status?.[0]?.status === Constants.TRAINING_PLANS.COMPLETED;
  const dueDate = new Date(status?.[0]?.dueDate).getTime();
  const stepsComplete = status?.[0]?.stepsComplete;
  const percentComplete = Math.round((stepsComplete / plan?.stepsTotal) * 100);
  const timeSpentOnPlan = getTimeSpentOnPlanForTeamMember(status, teamMemberId);
  const timeSpentHrs = getHrsFromTotalMins(timeSpentOnPlan);
  const timeSpentMins = getMinsFromTotalMins(timeSpentOnPlan);
  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            category={plan?.category}
            className={className}
            data-testid={dataTestId}
            link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${plan?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${teamMemberId}`}
            progress={percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <PlanCardTitle>{getNameFromLanguage(plan?.name)}</PlanCardTitle>
              {!!isComplete ? (
                <PlanCardSubHeader
                  className="completedPlan"
                  data-testid="PlanTaskCount"
                >
                  {`${t('TrainingPlans.accountability.completedIn')} ${
                    timeSpentHrs > 0
                      ? `${timeSpentHrs} ${t('Generic.hour')}`
                      : ''
                  }
                  ${timeSpentMins} ${t('Generic.mins')}`}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader data-testid="PlanTaskCount">
                  {`${stepsComplete}/${plan?.stepsTotal} ${t(
                    'Generic.items',
                  )} ${t('TrainingPlans.accountability.completedIn')} ${
                    timeSpentHrs > 0
                      ? `${timeSpentHrs} ${t('Generic.hour')}`
                      : ''
                  }
                  ${timeSpentMins} ${t('Generic.mins')}`}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
            {!isComplete && !!status?.[0]?.dueDate && (
              <PlanCardTag
                color={getDueDateColor(dueDate)}
                data-testid="DateTag"
                text={getDueDateString(dueDate)}
                tooltip={t('TrainingPlans.accountability.due.dueDate', {
                  dueDate: new Date(dueDate).toLocaleDateString(),
                })}
              />
            )}
            {!!isComplete && (
              <PlanCardIcon
                icon={<CompleteRibbon />}
                tooltip={
                  <>
                    <strong>
                      {t('TrainingPlans.statusOptions.completed')}:
                    </strong>
                    {new Date(status?.[0]?.completionDate).toLocaleDateString()}
                  </>
                }
              />
            )}
          </PlanCard>
        </>
      )}
    </>
  );
};

ReportsTeamMemberPlanCard.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  plan: PropTypes.shape({
    category: PropTypes.string,
    created: PropTypes.string,
    estimatedMinutes: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.shape({
      en: PropTypes.string,
    }),
    ownerId: PropTypes.string,
    stepsTotal: PropTypes.number,
  }).isRequired,
  status: PropTypes.arrayOf(
    PropTypes.shape({
      stepsComplete: PropTypes.number,
      userId: PropTypes.string,
      completionDate: PropTypes.string,
      status: PropTypes.string,
      dueDate: PropTypes.string,
    }),
  ).isRequired,
  teamMemberId: PropTypes.string,
};

ReportsTeamMemberPlanCard.defaultProps = {
  className: '',
  'data-testid': 'MyPlan',
  teamMemberId: '',
};

const CompleteRibbon = styled(Ribbon)`
  height: 28px;
  width: 28px;
`;

export default ReportsTeamMemberPlanCard;
