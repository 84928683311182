import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RadioButtonList from 'sharedComponents/app/RadioButtonList';
import { TextField, Typography } from 'cfa-react-components';
import StepWizardPopUp from 'sharedComponents/app/popups/StepWizardPopUp';

const AddQuizPopUp = ({
  open,
  setOpen,
  operators,
  quizName,
  submitHandler,
}) => {
  const isMultiOperator = operators?.length > 1;
  const initialStep = isMultiOperator ? 1 : 2;
  const [operator, setOperator] = useState({});
  const [operatorSelected, setOperatorSelected] = useState(false);
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(initialStep);
  const [stepWizard, setStepWizard] = useState();
  const [quizNameInput, setQuizNameInput] = useState(quizName);

  const operatorsNameArray = operators.flat().map(({ name }) => name);

  useEffect(() => {
    if (operators.length && operators.length === 1) {
      setOperator(operators[0]);
    }
  }, [operators]);

  useEffect(() => {
    if (isMultiOperator) {
      setOperator({});
    }
    setStepIndex(initialStep);
    setOperatorSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStep, open]);

  const closePopup = () => {
    setStepIndex(initialStep);
    setOpen(false);
  };

  const selectOperator = operatorName => {
    const selectedOperator = operators.find(
      ({ name }) => name === operatorName,
    );
    setOperator(selectedOperator);
    setOperatorSelected(true);
  };

  const Step1 = () => (
    <>
      <DialogHeader gutterBottom variant="h3">
        {t('TrainingPlans.buildQuizzes.chooseOperator')}
      </DialogHeader>
      <DialogHeader variant="body1">
        {t('TrainingPlans.buildQuizzes.whichOperators')}
      </DialogHeader>
      <RadioButtonList
        defaultValue={''}
        listItems={operatorsNameArray}
        name={'operatorList'}
        setValue={selectOperator}
      />
    </>
  );

  const Step2 = () => (
    <>
      <DialogHeader variant="h3">
        {t('TrainingPlans.buildQuizzes.giveQuizName')}
      </DialogHeader>
      <TextField
        data-testid="InputQuizName"
        fullWidth
        onChange={e => setQuizNameInput(e.target.value)}
        onKeyDown={e =>
          e.key === 'Enter' && submitHandler({ operator, quizNameInput })
        }
        placeholder={t('TrainingPlans.buildQuizzes.quizNamePlaceholder')}
        value={quizNameInput}
      />
    </>
  );

  const stepsData = {
    1: {
      labelButtonDeny: t('Button.cancel'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        closePopup();
      },
      onClickNext: () => {
        stepWizard.nextStep();
      },
      isDisabledButtonNext: !operatorSelected,
      ContentData: Step1,
    },
    2: {
      labelButtonDeny: t('Button.back'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        isMultiOperator ? stepWizard.previousStep() : closePopup();
      },
      onClickNext: () => {
        submitHandler({ operator, quizNameInput });
      },
      isDisabledButtonNext: !quizNameInput,
      ContentData: Step2,
    },
  };

  const step = stepsData[stepIndex];

  return (
    <StepWizardPopUp
      closePopup={closePopup}
      initialStep={initialStep}
      open={open}
      setStepIndex={setStepIndex}
      setStepWizard={setStepWizard}
      step={step}
      steps={stepsData}
    />
  );
};

const DialogHeader = styled(Typography)`
  margin-bottom: 16px;
`;

AddQuizPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  operators: PropTypes.array,
  quizName: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
};

AddQuizPopUp.defaultProps = {
  quizName: '',
  operators: [],
};

export default AddQuizPopUp;
