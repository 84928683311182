import PropTypes from 'prop-types';
import { Tag, Tooltip } from 'cfa-react-components';
import styled from 'styled-components';

const PlanCardTag = ({
  className,
  color,
  'data-testid': dataTestId,
  text,
  tooltip,
}) => {
  return (
    <>
      {!!tooltip ? (
        <Tooltip
          content={tooltip}
          placement="top"
          showOnElementEvents={['hover']}
        >
          <CardTag
            className={className}
            color={color}
            data-testid={dataTestId}
            label={text}
            variant="filled"
          />
        </Tooltip>
      ) : (
        <CardTag
          className={className}
          color={color}
          data-testid={dataTestId}
          label={text}
          variant="filled"
        />
      )}
    </>
  );
};

PlanCardTag.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PlanCardTag.defaultProps = {
  className: '',
  'data-testid': 'Tag',
  tooltip: '',
};

const CardTag = styled(Tag)`
  text-wrap: nowrap;
`;

export default PlanCardTag;
