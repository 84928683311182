import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { createSlice } from '@reduxjs/toolkit';

const applyFilters = (plans, categoryFilters, searchFilter) => {
  let filteredPlans = plans.map(plan => ({
    ...plan,
    name: plan.courseName,
  }));
  if (categoryFilters.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { category } = plan.courseID ? plan : plan.checklist;
      const hasCategory =
        // Compliance plans do not have a category so we check for undefined
        (category === undefined &&
          categoryFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE)) ||
        categoryFilters.includes(category);
      return hasCategory;
    });
  }
  if (searchFilter.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const nameLocalLang = plan?.checklist
        ? getNameFromLanguage(plan.checklist.name)
        : getNameFromLanguage(plan.name);
      return nameLocalLang.toLowerCase().includes(searchFilter.toLowerCase());
    });
  }
  return filteredPlans;
};

const applySort = (plans, sort) => {
  let sortedPlans = plans;
  const liftedPlans = plans.map(plan => {
    if (plan.checklist) {
      return {
        ...plan,
        created: plan.checklist.created,
        name: plan.checklist.name,
      };
    } else {
      return {
        ...plan,
        created: plan.createdDate,
        name: plan.courseName,
      };
    }
  });

  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedPlans = liftedPlans.toSorted((a, b) =>
        getNameFromLanguage(a.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(b.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedPlans = liftedPlans.toSorted((a, b) =>
        getNameFromLanguage(b.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(a.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.NEWEST:
      sortedPlans = liftedPlans.toSorted(
        (a, b) => new Date(b.created) - new Date(a.created),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.OLDEST:
      sortedPlans = liftedPlans.toSorted(
        (a, b) => new Date(a.created) - new Date(b.created),
      );
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedPlans;
};

const applyPagination = (plans, page, pageSize) => {
  const total = plans.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedPlans = plans.slice(0, showing);
  return { loadedPlans, showing, total };
};

export const slice = createSlice({
  name: 'managePlansFilter',
  initialState: {
    plans: [],
    categoryFilters: [],
    searchFilter: '',
    sort: Constants.PLANS_SORT_OPTIONS.NEWEST,
    filteredPlans: [],
    sortedPlans: [],
    paginatedPlans: [],
    page: 1,
    pageSize: 12,
    showing: 0,
  },
  reducers: {
    /**
     * We share the same store between Training Plans --> Manage Plans and Reports --> Plans
     * because the data and designs are identical.  Rather than create a separate store with
     * a lot of extra lines of code, I thought clearing the store would be a simpler approach.
     */
    resetManagePlans: state => {
      state.categoryFilters = [];
      state.locationFilters = [];
      state.searchFilter = '';
      state.categoryFilters = [];
      state.page = 1;
      state.showing = 0;
      state.sort = Constants.PLANS_SORT_OPTIONS.NEWEST;
    },
    setManagePlans: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.plans = action.payload.plans;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    addManagePlansCategoryFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.categoryFilters = [...state.categoryFilters, action.payload.filter];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setManagePlansSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = action.payload.searchFilter;
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setManagePlansSort: (state, action) => {
      state.sort = action.payload.sort;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearManagePlansCheckboxFilters: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.categoryFilters = [];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearManagePlansSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = '';
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    loadMorePlans: state => {
      state.page += 1;
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    removeManagePlansFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      const { filter } = action.payload;
      if (state.categoryFilters.includes(filter)) {
        state.categoryFilters = state.categoryFilters.filter(
          categoryFilter => categoryFilter !== filter,
        );
      }
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addManagePlansCategoryFilter,
  clearManagePlansCheckboxFilters,
  clearManagePlansSearchFilter,
  loadMorePlans,
  removeManagePlansFilter,
  resetManagePlans,
  setManagePlans,
  setManagePlansSearchFilter,
  setManagePlansSort,
} = slice.actions;

export default slice.reducer;
