import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'subheader',
  initialState: {
    hasDropShadow: true,
  },
  reducers: {
    setSubheaderDropShadow: (state, action) => {
      state.hasDropShadow = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSubheaderDropShadow } = slice.actions;

export default slice.reducer;
