import Constants from 'constants/index';
import { validateTextField } from 'util/validateTextField';
import theme from 'styles/theme';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUserLanguage } from 'store/user/selectors';
import {
  Button,
  RadioGroup,
  RadioButton,
  TextField,
} from 'cfa-react-components';
import { IconPlus, IconX, IconCheck } from '@tabler/icons-react';

const BuildQuizzesAnswers = ({
  quiz,
  setQuiz,
  questionIndex,
  validationMode,
  setValidationMode,
  questionTemplateType,
}) => {
  const { t } = useTranslation();
  const userLanguage = useSelector(selectUserLanguage);

  const handleOptionText = (value, optionIndex) => {
    setQuiz(prevState => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: Object.values({
            ...prevState.questions[questionIndex].answers,
            [optionIndex]: {
              ...prevState.questions[questionIndex].answers[optionIndex],
              answer: {
                ...prevState.questions[questionIndex].answers[optionIndex]
                  .answer,
                [userLanguage]: value,
              },
            },
          }),
        },
      }),
    }));
  };

  const handleCorrectOption = optionIndex => {
    setQuiz(prevState => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: prevState.questions[questionIndex].answers.map(
            (answer, index) => {
              if (index === optionIndex) {
                return {
                  ...prevState.questions[questionIndex].answers[index],
                  correct: true,
                };
              } else {
                return {
                  ...prevState.questions[questionIndex].answers[index],
                  correct: false,
                };
              }
            },
          ),
        },
      }),
    }));
  };

  const addOption = () => {
    setValidationMode(false);
    setQuiz(prevState => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: [
            ...prevState.questions[questionIndex].answers,
            ...[Constants.DEFAULT_QUIZ_ANSWER],
          ],
        },
      }),
    }));
  };

  const deleteOption = optionIndex => {
    setQuiz(prevState => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: prevState.questions[questionIndex].answers.filter(
            (answer, index) => index !== optionIndex,
          ),
        },
      }),
    }));
  };

  return (
    <StyledOptionsWrapper>
      <RadioGroup defaultValue={''} orientation="vertical">
        {quiz.questions[questionIndex]?.answers?.map((option, optionIndex) => {
          return (
            <StyledOptionWrapper
              $correctOption={option.correct}
              key={`questionOption-${optionIndex}`}
            >
              <StyledRadioButton
                $correctOption={option.correct}
                onClick={() => handleCorrectOption(optionIndex)}
                value={optionIndex}
              />
              <StyledTextFieldWrapper $correctOption={option.correct}>
                <TextField
                  data-testid={`${'EnterAnswer'}${optionIndex + 1}`}
                  errorText={
                    !validateTextField(option?.answer?.[userLanguage] ?? '') &&
                    validationMode
                      ? t('TrainingPlans.buildQuizzes.errorQuestion')
                      : ''
                  }
                  fullWidth
                  label={`${t('TrainingPlans.buildQuizzes.option')} ${
                    optionIndex + 1
                  }`}
                  maxLength={255}
                  onChange={e => handleOptionText(e.target.value, optionIndex)}
                  placeholder={t('TrainingPlans.buildQuizzes.enterAnswer')}
                  required
                  value={option?.answer?.[userLanguage] ?? ''}
                />
                {option.correct && (
                  <IconCheck
                    color={`${theme.semanticColors.success}`}
                    size={20}
                    style={{
                      position: 'absolute',
                      top: 'calc(50% - 10px)',
                      right: '16px',
                    }}
                  />
                )}
              </StyledTextFieldWrapper>
              {quiz.questions[questionIndex]?.answers?.length > 2 && (
                <StyledDeleteOption>
                  <IconX
                    color={`${theme.grayScale.gray6}`}
                    onClick={() => deleteOption(optionIndex)}
                    size={32}
                  />
                </StyledDeleteOption>
              )}
            </StyledOptionWrapper>
          );
        })}
      </RadioGroup>
      {questionTemplateType.isMultipleChoiceTemplate && (
        <>
          <StyledTextButton
            color="secondary"
            onClick={addOption}
            size="lg"
            variant="text"
          >
            <IconPlus />
            {t('Button.addOption')}
          </StyledTextButton>
        </>
      )}
    </StyledOptionsWrapper>
  );
};

const StyledOptionsWrapper = styled.div`
  margin: 1em 0;
`;

const StyledRadioButton = styled(RadioButton)`
  display: flex;
  margin-top: 26px;

  .radio {
    border-color: ${props =>
      props.$correctOption === true
        ? `${theme.semanticColors.success}`
        : null} !important;
  }
  .cfa-radiobutton-inner-circle {
    border: ${props =>
      props.$correctOption === true
        ? `6px solid ${theme.semanticColors.success}`
        : null} !important;
  }
`;

const StyledTextFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  input {
    padding-right: ${props => (props.$correctOption === true ? `3em` : null)};
  }
`;

const StyledDeleteOption = styled.div`
  display: flex;
  align-items: center;
  margin: 26px 0.5em 0 0.5em;
  height: 49px;
`;

//need !important to override our default button colors
const StyledTextButton = styled(Button)`
  color: ${theme.primaryPalette.navyBlue} !important;
`;

const StyledOptionWrapper = styled.div`
  background: ${props =>
    props.$correctOption === true ? 'rgba(7, 126, 76, 0.1)' : null};
  border: ${props =>
    props.$correctOption === true
      ? `1px solid ${theme.semanticColors.success}`
      : '1px solid transparent'};
  border-radius: 4px;
  padding: 8px 8px 16px 4px;
  display: flex;
  margin-bottom: 1em;
`;

BuildQuizzesAnswers.propTypes = {
  quiz: PropTypes.object.isRequired,
  setQuiz: PropTypes.func.isRequired,
  validationMode: PropTypes.bool.isRequired,
  setValidationMode: PropTypes.func.isRequired,
  questionIndex: PropTypes.number.isRequired,
  questionTemplateType: PropTypes.object.isRequired,
};

export default BuildQuizzesAnswers;
