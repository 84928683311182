import { useContext } from 'react';
import { AmplitudeContext } from './AmplitudeContext';

export const useAmplitudeTrack = () => {
  const context = useContext(AmplitudeContext);
  if (context === undefined) {
    throw new Error('useAmplitude must be used within a AmplitudeProvider');
  }
  return context;
};
