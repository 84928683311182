import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CardContent } from 'cfa-react-components';

const PlanCardContent = ({ children }) => {
  return <PlanCardContentContainer>{children}</PlanCardContentContainer>;
};

PlanCardContent.propTypes = {
  children: PropTypes.node.isRequired,
};

const PlanCardContentContainer = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  max-width: 100%;
  padding: 0px !important;
`;

export default PlanCardContent;
