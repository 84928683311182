import Constants from 'constants/index';
import i18next from 'i18next';

export const getDueDateColor = dueDate => {
  const timeDifference = dueDate - new Date().getTime();
  const daysUntilDue = timeDifference / (1000 * 3600 * 24);

  return Math.ceil(daysUntilDue) >= 7
    ? Constants.DUEDATE_COLORS.DARK_GREEN
    : Math.ceil(daysUntilDue) < 7 && Math.ceil(daysUntilDue) >= 0
    ? Constants.DUEDATE_COLORS.TAN
    : Constants.DUEDATE_COLORS.PURPLE;
};

export const getDueDateString = dueDate => {
  const timeDifference = dueDate - new Date().getTime();
  const daysUntilDue = timeDifference / (1000 * 3600 * 24);
  const weeksUntilDue = daysUntilDue / 7;

  return Math.ceil(daysUntilDue) === 7
    ? i18next.t('TrainingPlans.accountability.due.oneWeek')
    : Math.ceil(daysUntilDue) < 0
    ? i18next.t('TrainingPlans.accountability.due.overdue')
    : Math.ceil(daysUntilDue) === 1
    ? i18next.t('TrainingPlans.accountability.due.oneDay')
    : Math.ceil(daysUntilDue) < 7
    ? i18next.t('TrainingPlans.accountability.due.xDays', {
        daysLeft: Math.ceil(daysUntilDue),
      })
    : Math.ceil(daysUntilDue) >= 8 && Math.floor(weeksUntilDue) === 1
    ? i18next.t('TrainingPlans.accountability.due.oneWeek')
    : i18next.t('TrainingPlans.accountability.due.xWeeks', {
        weeksLeft: Math.floor(weeksUntilDue),
      });
};
