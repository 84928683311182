import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import orderBy from 'lodash/orderBy';
import { createSlice } from '@reduxjs/toolkit';

const applyFilters = (
  myPlans,
  categoryFilters,
  searchFilter,
  showOnlyPlansInProgress = false,
) => {
  let filteredPlans = myPlans.map(plan => {
    if (plan.checklist) {
      return {
        ...plan,
        name: plan.checklist.name,
        isCompleted:
          plan?.status?.[0].status === Constants.TRAINING_PLANS.COMPLETED,
      };
    } else {
      return {
        ...plan,
        name: plan.courseName,
        isCompleted:
          plan?.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
          plan?.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED,
      };
    }
  });
  if (showOnlyPlansInProgress) {
    filteredPlans = filteredPlans.filter(plan => {
      return !plan.isCompleted;
    });
  }
  if (searchFilter.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { name } = plan;
      const nameLocalLang = getNameFromLanguage(name);
      return nameLocalLang.toLowerCase().includes(searchFilter.toLowerCase());
    });
    return filteredPlans;
  }
  if (categoryFilters.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { category } = plan.courseName ? plan : plan.checklist;
      return (
        (category === undefined &&
          categoryFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE)) ||
        categoryFilters.includes(category)
      );
    });
  }
  return filteredPlans;
};

const applySort = (plans, sort) => {
  let sortedPlans = plans;
  const liftedPlans = plans.map(plan => {
    if (plan.checklist) {
      return {
        ...plan,
        dueDate: new Date(plan?.status?.[0].dueDate).getTime(),
        name: plan.checklist.name,
      };
    } else {
      return {
        ...plan,
        dueDate: !!plan?.dueDate ? new Date(plan.dueDate).getTime() : 0,
        name: plan.courseName,
      };
    }
  });

  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedPlans = liftedPlans.toSorted((a, b) =>
        getNameFromLanguage(a.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(b.name)?.toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedPlans = liftedPlans.toSorted((a, b) =>
        getNameFromLanguage(b.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(a.name)?.toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.DUE_DATE:
      const currentDate = new Date().getTime();
      sortedPlans = orderBy(
        liftedPlans,
        [
          plan =>
            plan.dueDate && new Date(plan.dueDate) < currentDate ? -1 : 1,
          plan =>
            plan.dueDate && new Date(plan.dueDate) > currentDate ? 1 : 0,
        ],
        ['asc', 'desc'],
      );
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedPlans;
};

const applyPagination = (plans, page, pageSize) => {
  const total = plans.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedPlans = plans.slice(0, showing);
  return { loadedPlans, showing, total };
};

export const slice = createSlice({
  name: 'myPlansFilter',
  initialState: {
    plans: [],
    categoryFilters: [],
    sort: Constants.PLANS_SORT_OPTIONS.DUE_DATE,
    sortedPlans: [],
    filteredPlans: [],
    paginatedPlans: [],
    searchFilter: '',
    page: 1,
    pageSize: 12,
    showing: 0,
    showOnlyInProgressPlans: true,
  },
  reducers: {
    toggleShowInProgressOnly: state => {
      state.showOnlyInProgressPlans = !state.showOnlyInProgressPlans;
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    addMyPlansCategoryFilter: (state, action) => {
      state.categoryFilters = [...state.categoryFilters, action.payload.filter];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    removeMyPlansFilter: (state, action) => {
      const { filter } = action.payload;
      if (state.categoryFilters.includes(filter)) {
        state.categoryFilters = state.categoryFilters.filter(
          categoryFilter => categoryFilter !== filter,
        );
      }
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setMyPlans: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.plans = action.payload.plans;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setMyPlansSort: (state, action) => {
      state.sort = action.payload.sort;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearMyPlansCheckboxFilters: (state, action) => {
      state.categoryFilters = [];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setMyPlansSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = action.payload.searchFilter;
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    loadMoreMyPlans: state => {
      state.page += 1;
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearMyPlansSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = '';
      state.showOnlyInProgressPlans = true;
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
        state.showOnlyInProgressPlans,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
  },
});

export const {
  addMyPlansCategoryFilter,
  setMyPlans,
  loadMoreMyPlans,
  setMyPlansSort,
  removeMyPlansFilter,
  clearMyPlansCheckboxFilters,
  setMyPlansSearchFilter,
  clearMyPlansSearchFilter,
  toggleShowInProgressOnly,
} = slice.actions;

export default slice.reducer;
