import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'webAlertBanner',
  initialState: {
    lastDismissed: 0,
  },
  reducers: {
    dismissWebAlertBanner: state => {
      state.lastDismissed = Math.floor(Date.now() / 1000);
    },
  },
});

export const { dismissWebAlertBanner } = slice.actions;

export default slice.reducer;
