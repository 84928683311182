import Constants from 'constants/index';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { arrayToCommaString } from 'util/arrayToCommaString';
import { messageReactNative } from 'util/messageReactNative';
import { htmlFromPrintJson } from 'util/PrintJSConversion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetAssignableChecklistsQuery,
  useGetCourseReportQuery,
  useGetIsCourseEnabledQuery,
  useGetOperatorsQuery,
  useGetTeamMembersQuery,
} from 'services/pathwayApi';
import styled from 'styled-components';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { setHeader } from 'store/header/slice';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
} from 'store/user/selectors';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components/dist/cjs';
import print from 'print-js';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import ReportsCompliancePlanCard from 'components/PlanCard/PlanCards/ReportsCompliancePlanCard';
import theme from 'styles/theme';

const ReportsCompliancePlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const [showChooseLocationsPlanPopup, setShowChooseLocationsPlanPopup] =
    useState(false);
  const [compliancePlans, setCompliancePlans] = useState([]);
  const [planId, setPlanId] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [statusReport, setStatusReport] = useState({
    courseId: '',
    courseName: '',
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const { data: operators } = useGetOperatorsQuery();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectLocationsWithTrainerPermission,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const isTrainerOrLeaderOrOperator =
    !!locationsWithAtLeastTrainerPermission.length ||
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;

  const OperatorsOfLocationsWithAtLeastLeader = operators?.filter(
    operator =>
      arrayIntersect(operator?.locations, locationsWithAtLeastLeaderPermission)
        .length > 0,
  );

  const { data: complianceStatusData } = useGetIsCourseEnabledQuery(
    OperatorsOfLocationsWithAtLeastLeader?.[0]?.id ?? operators?.[0]?.id,
    {
      skip: !operators?.length,
    },
  );

  const { data: unOrderedPlans, isFetching } =
    useGetAssignableChecklistsQuery();

  const { data: allTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: statusReportData, isSuccess: isStatusReportSuccess } =
    useGetCourseReportQuery(
      {
        courseId: planId,
        location: selectedLocations?.length
          ? selectedLocations
          : locationsWithAtLeastTrainer,
      },
      {
        skip: !planId || !isTrainerOrLeaderOrOperator,
      },
    );

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Check if compliance plan is enabled
  useEffect(() => {
    if (complianceStatusData && unOrderedPlans) {
      const compliancePlansWithStatus = unOrderedPlans?.courses
        ?.filter(plan => plan?.courseID)
        .map(course => ({
          ...course,
          enabled: complianceStatusData?.[course?.id]?.enabled ?? false,
        }));

      setCompliancePlans(compliancePlansWithStatus);
    }
  }, [complianceStatusData, dispatch, unOrderedPlans]);

  // Status Report
  useEffect(() => {
    if (isStatusReportSuccess) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData]);

  const translatedName = t('Generic.name');
  const translatedDOB = t('Generic.dateOfBirth');
  const translatedCompleted = t('Generic.completed');

  const statusReportToPrint = [...statusReport?.userData]
    .sort((teamMemberA, teamMemberB) => {
      return teamMemberA.firstName.localeCompare(teamMemberB.firstName);
    })
    .map(report => {
      return {
        [translatedName]: `${report.firstName} ${report.lastName}`,
        [translatedDOB]: report.birthDate ? report.birthDate : t('Generic.na'),
        [translatedCompleted]: report.courseCompletionDate
          ? report.courseCompletionDate
          : t('Generic.notInCompliance'),
      };
    });

  const reportToPrint = {
    printable: statusReportToPrint,
    properties: [translatedName, translatedDOB, translatedCompleted],
    type: 'json',
    header: `
           <div class="header">
            <span class="category">${t(
              'TrainingPlans.planCategories.compliance',
            )}</span>
           <span class="courseName">${statusReport?.courseName}</span>
            <span class="locations">${t('Generic.at')} ${
      !selectedLocations.length && statusReport?.locations
        ? statusReport?.locations.toString()
        : arrayToCommaString(selectedLocations, t('Generic.and'))
    }</span>
        </div>
          `,
    style: theme.reports.style,
    gridHeaderStyle: theme.reports.gridHeader,
    gridStyle: theme.reports.grid,
  };

  const onPrintReport = report => {
    print(reportToPrint?.printable?.length ? reportToPrint : report);
    const printHTML = htmlFromPrintJson(
      reportToPrint?.printable?.length ? reportToPrint : report,
    );
    messageReactNative(Constants.RN_MESSAGE_TYPES.PRINT, printHTML.innerHTML);

    setStatusReport({
      courseId: '',
      courseName: '',
      locations: [''],
      timeStamp: '',
      userData: [],
    });
    setPlanId('');
    setSelectedLocations([]);
    setShowChooseLocationsPlanPopup(false);
  };

  // Trigger Print Report
  useEffect(() => {
    if (
      reportToPrint?.printable?.length &&
      locationsWithAtLeastTrainer?.length <= 1
    ) {
      onPrintReport(reportToPrint);
    }
    //eslint-disable-next-line
  }, [locationsWithAtLeastTrainer, reportToPrint]);

  const handlePrintReport = id => {
    setPlanId(id);
    locationsWithAtLeastTrainer?.length > 1 &&
      setShowChooseLocationsPlanPopup(true);
  };

  const onPrintReportCancel = () => {
    setShowChooseLocationsPlanPopup(false);
    setSelectedLocations([]);
  };

  return (
    <>
      <SearchHeaderContainer $isSmAndDown={isSmAndDown}>
        <Typography variant="h3">{t('Reports.tabCompliancePlans')}</Typography>
      </SearchHeaderContainer>
      <PlanCardsList $isSmAndDown={isSmAndDown}>
        <Typography fontWeight="bold" variant="body1">{`${
          compliancePlans?.length ?? 0
        } ${t('TrainingPlans.filtering.plans')}`}</Typography>
        <LoadingOverlay isOpen={isFetching} />
        {!!compliancePlans?.length && !isFetching && (
          <ReportsCardContainer>
            {compliancePlans.map(plan => (
              <ReportsCompliancePlanCard
                assignedUsers={plan?.assignedUsers}
                completedUsers={plan?.completedUsers}
                course={{
                  courseID: plan?.courseID,
                  courseName: plan?.courseName,
                  createdDate: plan?.createdDate,
                  enabled: plan?.enabled,
                  id: plan?.id,
                }}
                data-testid="ReportsCompliancePlanCard"
                enrollments={plan?.enrollments}
                key={plan.courseID}
                locations={locationsWithAtLeastLeaderPermission}
                onPrintReport={({ id }) => handlePrintReport(id)}
                totalTeamMembers={
                  allTeamMembersData.filter(
                    (value, innerIndex, self) =>
                      innerIndex ===
                      self.findIndex(user => user.adId === value.adId),
                  ).length
                }
              />
            ))}
          </ReportsCardContainer>
        )}
      </PlanCardsList>
      <ConfirmationModal
        bodyText={t('TrainingPlans.whichLocationsPrintReport')}
        children={locationsWithAtLeastTrainer.map((id, idx) => (
          <CheckboxList
            id={id}
            idx={idx}
            key={idx}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
          />
        ))}
        headerText={t('Generic.chooseLocation')}
        isDisabled={!selectedLocations?.length}
        isOpen={showChooseLocationsPlanPopup}
        onClose={onPrintReportCancel}
        primaryButtonHandler={onPrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  margin: ${({ $isSmAndDown }) => !$isSmAndDown && '0 6em'};
`;

const SearchHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'column-reverse' : 'row'};
  justify-content: space-between;
  align-items: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'flex-start' : 'center'};
  margin: 16px 0;
`;

const ReportsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default ReportsCompliancePlansTab;
