import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import Constants from 'constants/index';
import PropTypes from 'prop-types';
import PlanCardDate from 'components/PlanCard/PlanCardComponents/PlanCardDate';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';

const ManagePlansPlanCard = ({
  assignedUsers,
  className,
  completedUsers,
  'data-testid': dataTestId,
  plan,
}) => {
  const { t } = useTranslation();
  const percentComplete = (completedUsers / assignedUsers) * 100;
  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            category={plan?.category}
            className={className}
            data-testid={dataTestId}
            link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${plan?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
            progress={percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <PlanCardTitle>{getNameFromLanguage(plan?.name)}</PlanCardTitle>
              <PlanCardSubHeader data-testid="PlanTaskCount">
                {`${completedUsers}/${assignedUsers} ${t(
                  'Generic.teamMembersCompleted',
                )}`}
              </PlanCardSubHeader>
            </PlanCardContent>
            <PlanCardDate date={plan?.created} />
          </PlanCard>
        </>
      )}
    </>
  );
};

ManagePlansPlanCard.propTypes = {
  assignedUsers: PropTypes.number,
  className: PropTypes.string,
  completedUsers: PropTypes.number,
  'data-testid': PropTypes.string,
  plan: PropTypes.shape({
    category: PropTypes.string,
    created: PropTypes.string,
    estimatedMinutes: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.shape({
      en: PropTypes.string,
    }),
    ownerId: PropTypes.string,
    stepsTotal: PropTypes.number,
  }).isRequired,
};

ManagePlansPlanCard.defaultProps = {
  assignedUsers: 0,
  className: '',
  completedUsers: 0,
  'data-testid': 'TeamPlanCard',
};

export default ManagePlansPlanCard;
