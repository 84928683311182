import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { messageReactNative } from 'util/messageReactNative';
import { htmlFromPrintJson } from 'util/PrintJSConversion';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setHeader } from 'store/header/slice';
import { hidePrintReport } from 'store/printReport/slice';
import {
  clearPlansCategoryFilter,
  clearPlansSearchFilter,
  clearPlansStatusFilter,
  loadMorePlans,
  setPlans,
  setPlansCategoryFilter,
  setPlansSearchFilter,
  setPlansSort,
  setPlansStatusFilter,
} from 'store/reports/slice';
import {
  selectSearchFilter,
  selectFilters,
  selectSort,
  selectPlansPagination,
  selectSortedPaginatedAndFilteredPlans,
  selectSortedAndFilteredPlans,
} from 'store/reports/selector';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components/dist/cjs';
import { Link, useParams } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import { useGetReportsPlansQuery } from 'services/pathwayApi';
import { printReport } from 'store/printReport/selectors';
import print from 'print-js';
import ReportsTeamMemberPlanCard from 'components/PlanCard/PlanCards/ReportsTeamMemberPlanCard';
import ReportsTeamMemberCompliancePlanCard from 'components/PlanCard/PlanCards/ReportsTeamMemberCompliancePlanCard';
import appTheme from 'styles/theme';
import { NoMessage } from 'containers/TrainingPlans/ManagePlans/ManagePlanView';

const PlansView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const searchFilter = useSelector(selectSearchFilter);
  const currentFilters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const { showing, total } = useSelector(selectPlansPagination);
  const plans = useSelector(selectSortedPaginatedAndFilteredPlans);
  const plansToPrint = useSelector(selectSortedAndFilteredPlans);
  const printReportStatus = useSelector(printReport);

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  const { data, isFetching, isSuccess } = useGetReportsPlansQuery({
    userId: id,
    skip: !id,
  });

  useEffect(() => {
    let plansData = [];
    let courses = [];

    // Non-Compliance Plans
    if (isSuccess && data.checklistResults) {
      plansData = data.checklistResults;
    }
    // Compliance Plans
    if (isSuccess && data.coursesResults?.[0]?.enrollments) {
      courses =
        data?.coursesResults?.[0]?.enrollments &&
        Object.keys(data?.coursesResults?.[0]?.enrollments).map(
          key => data?.coursesResults?.[0]?.enrollments?.[key],
        );
    }

    const combinedPlans = plansData.concat(courses);

    dispatch(
      setPlans({
        plans: combinedPlans,
      }),
    );
  }, [dispatch, isSuccess, data]);

  useEffect(() => {
    if (plansToPrint && printReportStatus) {
      onPrintReport(plansToPrint);
      dispatch(hidePrintReport());
    }
    //eslint-disable-next-line
  }, [dispatch, plansToPrint, printReportStatus]);

  const getTimeString = time => {
    const timeSpentHrs = Math.floor(time / 60);
    const timeSpentMins = time % 60;
    return `${
      timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
    } ${timeSpentMins} ${t('Generic.mins')}`;
  };

  const translatedPlanName = t('Generic.plan');
  const translatedItemsCompleted = t('Generic.itemsCompleted');
  const translatedTotalTimeSpentOnPlan = t('Generic.totalTimeSpentOnPlan');
  const translatedStatus = t('Generic.status');
  const translatedCompletionDate = t('Generic.completionDate');

  const onPrintReport = plansData => {
    const statusReportToPrint = [...plansData]
      .sort((planA, planB) => {
        return planA?.checklist
          ? getNameFromLanguage(planA?.checklist?.name).localeCompare(
              getNameFromLanguage(planB?.checklist?.name),
            )
          : getNameFromLanguage(planA?.courseName).localeCompare(
              getNameFromLanguage(planB?.courseName),
            );
      })
      .map(plan => {
        return {
          [translatedPlanName]: plan.checklist
            ? getNameFromLanguage(plan.checklist.name)
            : getNameFromLanguage(plan.courseName),
          [translatedItemsCompleted]: plan.checklist
            ? `${plan.status[0].stepsComplete}/${plan.checklist.stepsTotal}`
            : `${plan.percentComplete}%`,
          [translatedTotalTimeSpentOnPlan]: plan.checklist
            ? `${getTimeString(
                plan.status[0]?.steps?.reduce(
                  (acc, step) =>
                    acc + Math.round((step?.stepDuration ?? 0) / 60),
                  0,
                ) ?? 0,
              )}`
            : t('Generic.na'),
          [translatedStatus]: plan.checklist
            ? t(
                `TrainingPlans.statusOptions.${
                  Constants.TRAINING_PLANS_STATUSES[plan.status[0].status]
                }`,
              )
            : t(
                `TrainingPlans.statusOptions.${
                  Constants.LEARN_UPON_TRAINING_PLAN_STATUSES[plan.status]
                }`,
              ),
          [translatedCompletionDate]:
            plan.checklist && plan.status[0].completionDate
              ? new Date(plan.status[0].completionDate).toLocaleDateString()
              : plan.courseName && plan.completedDate
              ? new Date(plan.completedDate).toLocaleDateString()
              : t('Generic.na'),
        };
      });

    const reportToPrint = {
      printable: statusReportToPrint,
      properties: [
        translatedPlanName,
        translatedItemsCompleted,
        translatedTotalTimeSpentOnPlan,
        translatedStatus,
        translatedCompletionDate,
      ],
      type: 'json',
      header: `
        <div class="header">
        <span>Team Member</span>
        <span class="courseName">${data?.user?.name}</span>
        </div>
        `,
      style: `.header { display: flex; flex-direction: column; align-items: center; font-weight: 900;}
                .courseName {margin-top: 0.5em; margin-bottom: 2em; }`,
      gridHeaderStyle: appTheme.reports.gridHeader,
      gridStyle: appTheme.reports.grid,
    };

    /**
     * There is a minor timing gap when a user clicks the printer icon to
     * when the report loads because we are first setting the ID of the
     * report to print, waiting on the query to get the data, then calling
     * the print function aferward.  We wait for the success status to be
     * returned so that we don't show empty data
     */
    print(reportToPrint);
    const printHTML = htmlFromPrintJson(reportToPrint);
    messageReactNative(Constants.RN_MESSAGE_TYPES.PRINT, printHTML.innerHTML);
  };

  const categoryLabels = {
    [Constants.PLAN_CATEGORIES.DEFAULT]: {
      translationString: t('TrainingPlans.planCategories.default'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.DEFAULT),
    },
    [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.backOfHouse'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.BACK_OF_HOUSE),
    },
    [Constants.PLAN_CATEGORIES.COMPLIANCE]: {
      translationString: t('TrainingPlans.planCategories.compliance'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE),
    },
    [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.frontOfHouse'),
      value: !!currentFilters.includes(
        Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE,
      ),
    },
    [Constants.PLAN_CATEGORIES.HOSPITALITY]: {
      translationString: t('TrainingPlans.planCategories.hospitality'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.HOSPITALITY),
    },
    [Constants.PLAN_CATEGORIES.LEADERSHIP]: {
      translationString: t('TrainingPlans.planCategories.leadership'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.LEADERSHIP),
    },
    [Constants.PLAN_CATEGORIES.ONBOARDING]: {
      translationString: t('TrainingPlans.planCategories.onboarding'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.ONBOARDING),
    },
  };

  const statusLabels = {
    [Constants.TRAINING_PLANS.COMPLETED]: {
      translationString: t('TrainingPlans.statusOptions.completed'),
      value: !!currentFilters.includes(Constants.TRAINING_PLANS.COMPLETED),
    },
    [Constants.TRAINING_PLANS.NOT_COMPLETED]: {
      translationString: t('TrainingPlans.statusOptions.notCompleted'),
      value: !!currentFilters.includes(Constants.TRAINING_PLANS.NOT_COMPLETED),
    },
  };

  const sortOptions = [
    {
      id: '1',
      translationString: t('TrainingPlans.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '2',
      translationString: t('TrainingPlans.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
    {
      id: '3',
      translationString: t('TrainingPlans.filtering.newest'),
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '4',
      translationString: t('TrainingPlans.filtering.oldest'),
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '5',
      translationString: t('TrainingPlans.filtering.dueDate'),
      value: Constants.PLANS_SORT_OPTIONS.DUE_DATE,
    },
  ];

  return (
    <>
      <StyledContent>
        <StyledLinkWrapper
          to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <StyledBackArrow />
          <StyledBackArrowText variant="body2">
            {t('Reports.backToTeamMembers')}
          </StyledBackArrowText>
        </StyledLinkWrapper>
        <SearchFilterHeader
          onChange={e =>
            dispatch(setPlansSearchFilter({ searchFilter: e.target.value }))
          }
          onClear={() => dispatch(clearPlansSearchFilter())}
          searchPlaceholder={t('Reports.filtering.searchForPlans')}
          searchValue={searchFilter}
          title={data?.user?.name ?? ''}
        />
        {!!isSmAndDown && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setPlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              plans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
            top={Constants.HEIGHT.MOBILE_TOP_NAV}
          >
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(clearPlansCategoryFilter({ filter: value }));
                } else {
                  dispatch(setPlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
            <CheckboxFilterSection
              labels={statusLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(clearPlansStatusFilter({ filter: value }));
                } else {
                  dispatch(setPlansStatusFilter({ filter: value }));
                }
              }}
              title={t('Browse.status')}
            />
          </FilterAndSortButton>
        )}
        <PlansList>
          {!isSmAndDown && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(clearPlansCategoryFilter({ filter: value }));
                  } else {
                    dispatch(setPlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
              <CheckboxFilterSection
                labels={statusLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(clearPlansStatusFilter({ filter: value }));
                  } else {
                    dispatch(setPlansStatusFilter({ filter: value }));
                  }
                }}
                title={t('Browse.status')}
              />
            </StickyFilterCard>
          )}
          <PlansContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setPlansSort({ sort: option.value }));
              }}
              onClear={() => {
                dispatch(clearPlansCategoryFilter());
              }}
              options={sortOptions}
              showClear={false}
              text={`${total ?? 0} ${t('Reports.tabPlans')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />
            <PlansCardsList>
              <LoadingOverlay isOpen={isFetching} />
              {plans
                .filter(enrolledCourse => !enrolledCourse.operatorDisabled)
                .map((plan, index) =>
                  !!plan.checklist ? (
                    <ReportsTeamMemberPlanCard
                      data-testid="TeamMemberPlan"
                      key={plan?.checklist?.id}
                      plan={plan?.checklist}
                      status={plan?.status}
                      teamMemberId={data?.user?.adId}
                    />
                  ) : (
                    <ReportsTeamMemberCompliancePlanCard
                      course={plan}
                      data-testid="TeamMemberCompliancePlan"
                      key={index}
                    />
                  ),
                )}
              {!plans?.length && !currentFilters?.length && !searchFilter && (
                <NoMessage message={t('TrainingPlans.noTrainingPlans')} />
              )}
              {(!!currentFilters?.length || !!searchFilter) && !total && (
                <NoMessage message={t('TrainingPlans.noPlansResults')} />
              )}
            </PlansCardsList>
            <LoadMorePaginator
              onClick={() => dispatch(loadMorePlans())}
              showing={showing}
              showingText={t('TrainingPlans.showingXOfYPlans', {
                showing,
                total: total ?? 0,
              })}
              total={total ?? 0}
            />
          </PlansContainer>
        </PlansList>
      </StyledContent>
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const StyledLinkWrapper = styled(Link)`
  display: flex;
  flexdirection: row;
  cursor: pointer;
  max-width: 250px;
`;

const StyledBackArrow = styled(IconArrowLeft)`
  margin-right: 0.5em;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
`;

const StyledBackArrowText = styled(Typography)`
  transform: translateY(1px);
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
`;

const PlansList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlansContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const PlansCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  gap: 8px;
`;

export default PlansView;
