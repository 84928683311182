import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { useDeviceInfo } from 'util/device';
import { isComplianceDocumentPath, isLeadershipDocumentPath } from 'util/url';
import { memo, useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import {
  useGetCompliancePlanUrlQuery,
  useGetDocumentQuery,
} from 'services/pathwayApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import styled from 'styled-components';
import DocumentHead from 'DocumentHead';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarCollapsed } from 'store/sideBar/slice';
import { incrementHistoryStackCount, setHeader } from 'store/header/slice';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { selectUserId } from 'store/user/selectors';
import useTrainingPlanTimer from 'hooks/useTrainingPlanTimer';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Card, CardContent, Typography } from 'cfa-react-components';
import GenericError from 'sharedComponents/app/GenericError';
import useDocumentCookieRefresh from 'hooks/useDocumentCookieRefresh';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { useIsNative } from 'hooks/useIsNative';

const Document = ({
  id,
  planId,
  stepId,
  userIds,
  stepStatus,
  isViewingFromTrainingPlan,
}) => {
  const { t } = useTranslation();
  const [errorMsg, setErrorMessage] = useState(null);
  const [iframeLoads, setIframeLoads] = useState(0);
  const { documentId, isCompliance } = useParams();
  const isNotComplianceDocument = !isCompliance;
  const hasNoDocumentId = !documentId;
  const {
    data: complianceUrl = {},
    isFetching: isFetchingComplianceUrl,
    error: complianceUrlError,
  } = useGetCompliancePlanUrlQuery(documentId, {
    refetchOnMountOrArgChange: true,
    skip: hasNoDocumentId || isNotComplianceDocument,
  });
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const isNative = useIsNative();
  const { data: document_detail = {}, error } = useGetDocumentQuery(
    id ? id : documentId,
    {
      refetchOnMountOrArgChange: true,
      skip: isCompliance,
    },
  );

  useDocumentCookieRefresh(id ? id : documentId, isCompliance);
  // we always refetch document query to make sure we get a valid auth cookie
  // We shouldnt display any docs when we know the url is missing either
  // the versionId or fileId

  Document.propTypes = {
    id: PropTypes.string,
    planId: PropTypes.string,
    stepId: PropTypes.string,
    userIds: PropTypes.arrayOf(PropTypes.string),
    stepStatus: PropTypes.string,
    isViewingFromTrainingPlan: PropTypes.bool,
  };

  Document.defaultProps = {
    id: '',
    planId: '',
    stepId: '',
    userIds: [],
    stepStatus: '',
    isViewingFromTrainingPlan: false,
  };
  const versionId =
    getNameFromLanguage(document_detail?.references)?.versionId ??
    getNameFromLanguage(document_detail?.references)?.id;
  const fileId =
    getNameFromLanguage(document_detail?.references)?.fileId ??
    document_detail?.id;
  const gameUrl = getNameFromLanguage(document_detail?.references)?.reference;
  const documentName = getNameFromLanguage(document_detail?.references)?.name;
  const documentType = document_detail?.type;
  const documentFormat = document_detail?.format;
  const dispatch = useDispatch();
  const fullViewDocument = includes(
    Constants.EXPANDED_FILE_FORMAT_TYPES,
    documentType,
  );
  const userId = useSelector(selectUserId);
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const location = useLocation();
  const isLeadershipDocument = isLeadershipDocumentPath(location);
  const isComplianceDocument = isComplianceDocumentPath(location);
  const isMobile = isNative || !isDesktopWidth;

  const calculateIframeHeight = () => {
    if (isMobile) {
      //is mobile
      if (isLeadershipDocument || isComplianceDocument) {
        return `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV})`;
      } else {
        return `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`;
      }
    } else {
      //is desktop
      if (isLeadershipDocument) {
        return `calc(100vh - ${Constants.HEIGHT.DESKTOP_HEADER})`;
      } else if (isComplianceDocument) {
        return '100vh';
      } else {
        return `calc(100vh - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`;
      }
    }
  };

  useEffect(() => {
    if (isLeadershipDocument) {
      dispatch(setHeader(t('Leadership.ascendOnDemand')));
    }
    return () => {
      dispatch(setHeader(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error && !isCompliance) {
      console.error(error);
    }
    if (error && error.status === 403 && !isCompliance) {
      setUnauthorizedError(true);
    }
  }, [error, isCompliance]);

  if (complianceUrlError && isCompliance) {
    console.error(complianceUrlError);
  }

  const onMessageReceivedFromContent = useCallback(eventMessage => {
    if (eventMessage.origin === Constants.PATHWAY_API_URL) {
      // This is a message sent to indicate a successful page load
      if (eventMessage.data === 'ok') {
        return;
      }

      if (eventMessage.data.type === 'Error') {
        setErrorMessage(eventMessage.data.code);
      }
    }
  }, []);

  const onIframeLoad = () => {
    const updatedIframeLoads = iframeLoads + 1;
    setIframeLoads(updatedIframeLoads);
    if (updatedIframeLoads > 1) {
      dispatch(incrementHistoryStackCount());
    }
  };

  useEffect(() => {
    if (fullViewDocument) {
      dispatch(setSidebarCollapsed());
    }
  }, [dispatch, fullViewDocument]);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromContent);

    return () => {
      window.removeEventListener('message', onMessageReceivedFromContent);
    };
  }, [onMessageReceivedFromContent]);

  // auto log time viewing doc to api if from training plan
  const timerUserIds = userIds && userIds?.length > 0 ? userIds : userId;
  const [startTrainingTimer, stopTrainingTimer] = useTrainingPlanTimer(
    planId,
    stepId,
    timerUserIds,
    stepStatus,
  );

  // Catch iFrame scroll events and forward them to the native app
  window.updateScrollPosition = top => {
    const scrollEvent = new MessageEvent('iFrameScroll', {
      data: JSON.stringify(top),
    });
    window.dispatchEvent(scrollEvent);
  };

  useEffect(() => {
    if (isViewingFromTrainingPlan || Constants.IS_IN_CYPRESS_TEST) {
      startTrainingTimer();
    }
    return () => {
      if (isViewingFromTrainingPlan || Constants.IS_IN_CYPRESS_TEST) {
        stopTrainingTimer();
      }
    };
  }, [isViewingFromTrainingPlan, startTrainingTimer, stopTrainingTimer]);

  // this XHR request pway api calls xyleme  /documents endpoint end sends back this data
  // which is used to get the url used in the iframe src to the document -  index.html running on
  // xylemes server. The XHR response also includes the auth cookie xyleme uses to allow access
  // to the document. Without this cookie the iframe content will not be loaded. React Toolkit Query
  // is a wrapper around the DOM fetch API and uses "credentials": include as a fetch arg to save and
  // add this cookie to headers which must be present in the iframe src link for content_url
  const content_url =
    documentType === Constants.EXPANDED_FILE_FORMAT_TYPES.GAME
      ? gameUrl
      : documentType === Constants.EXPANDED_FILE_FORMAT_TYPES.JOB_AID
      ? `${
          Constants.PATHWAY_API_CONTENT_URL
        }/${versionId}/${fileId}/${encodeURIComponent(documentName)}`
      : isCompliance
      ? complianceUrl.url
      : documentFormat === Constants.EXPANDED_FILE_FORMAT_TYPES.SCORM
      ? `${Constants.PATHWAY_API_CONTENT_URL}/${versionId}/${fileId}/scorm-index.html`
      : `${Constants.PATHWAY_API_CONTENT_URL}/${versionId}/${fileId}/index.html`;

  const handleSuccessVideoLoad = () => {};

  const content_iframe = () => {
    if ((!versionId && !isCompliance) || (!fileId && !isCompliance)) {
      return null;
    }
    if (documentType === Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE) {
      const videoReference = getNameFromLanguage(document_detail?.references);
      if (!videoReference) {
        return <GenericError />;
      }
      const brightcoveSrcUrl = new URL(videoReference.src);
      const brightcoveAccountId = brightcoveSrcUrl.pathname.match(/\d+/);
      const brightcoveVideoId = brightcoveSrcUrl.searchParams.get('videoId');
      return (
        <StyledVideoContainer>
          <StyledVideoTitle $isDesktop={isDesktopWidth}>
            {documentName}
          </StyledVideoTitle>
          <StyledVideoCard $isDesktop={isDesktopWidth} elevation={1}>
            <CardContent>
              <ReactPlayerLoader
                accountId={brightcoveAccountId}
                onSuccess={handleSuccessVideoLoad}
                options={{
                  aspectRatio: '16:9',
                  autoplay: false,
                  controls: false,
                  fluid: true,
                  loop: false,
                  muted: false,
                  playsInline: true,
                  responsive: true,
                }}
                videoId={brightcoveVideoId}
              />
            </CardContent>
          </StyledVideoCard>
        </StyledVideoContainer>
      );
    }
    return (
      <StyledIFrame
        $calculatedHeight={calculateIframeHeight()}
        allow="fullscreen"
        className="content-iframe"
        onLoad={onIframeLoad}
        src={content_url}
      />
    );
  };

  return (
    <DocumentContainer className="p-0 h-100" fluid>
      <LoadingOverlay isOpen={isFetchingComplianceUrl} />
      <DocumentHead pageTitle={documentName} />
      <StyledContentWrapper>
        {!errorMsg && content_iframe()}

        <ConfirmationModal
          bodyText={t('ResourceNotFoundError.errorParagraph')}
          headerText={t('ResourceNotFoundError.errorHeader')}
          isError={true}
          isOpen={errorMsg === Constants.API_ERROR_CODES.RESOURCE_NOT_FOUND}
          onClose={() => window.history.go(-1)}
          primaryButtonHandler={() => window.history.go(-1)}
          primaryButtonText={t('Button.returnToPreviousPage')}
        />

        <ConfirmationModal
          bodyText={t('LoadingResourceError.errorParagraph')}
          headerText={t('LoadingResourceError.errorHeader')}
          isError={true}
          isOpen={errorMsg === Constants.API_ERROR_CODES.ERROR_LOADING_RESOURCE}
          onClose={() => window.location.reload()}
          primaryButtonHandler={() => window.location.reload()}
          primaryButtonText={t('Button.reloadThePage')}
        />

        <ConfirmationModal
          bodyText={t('LoadingResourceError.unauthorizedParagraph')}
          headerText={t('LoadingResourceError.unauthorizedHeader')}
          isOpen={unauthorizedError}
          onClose={() => (window.location.href = '/')}
          primaryButtonHandler={() => (window.location.href = '/')}
          primaryButtonText={t('Button.returnToHomepage')}
        />
      </StyledContentWrapper>
    </DocumentContainer>
  );
};

const DocumentContainer = styled(Container)`
  position: relative;
`;
const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledVideoCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  min-width: ${({ $isDesktop }) => ($isDesktop ? '840px !important' : null)};
  margin: ${({ $isDesktop }) => ($isDesktop ? '0 auto 2em auto' : '0.25em 0')};
`;

const StyledVideoTitle = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
  font-size: 32px;
  font-weight: 700;
  padding: 10px 0 24px 0;
  line-height: 40px;
  margin-top: 20px;
  margin-left: ${({ $isDesktop }) => ($isDesktop ? null : '1em')};
  text-align: ${({ $isDesktop }) => ($isDesktop ? 'center' : 'left')};
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
`;

const StyledIFrame = styled.iframe`
  border: 0;
  width: 100%;
  height: ${({ $calculatedHeight }) => $calculatedHeight};
`;

export default memo(Document);
// export default Document;
