import { isSignalPath } from 'util/url';
import { useEffect } from 'react';
import { sendNativeMessage } from 'hooks/useNativeListener';
import { getCurrentLanguage } from 'i18n/language';
import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import RoutesContainer from '../Routes';
import Navbar from './Navbar/Navbar';
import Header from './Header/Header';
import Subheader from './Subheader/Subheader';
import WebAlertBannerContainer from './WebAlertBanner/WebAlertBannerContainer';
import SmartBannerContainer from './SmartBanner/SmartBannerContainer';
import Footer from './Footer/Footer';
import TrainingModeWrapper from './TrainingModeWrapper/TrainingModeWrapper';
import MainContent from './Content/MainContent';

const Layout = () => {
  const location = useLocation();

  const isSignalHeadlessView = isSignalPath(location) ?? false;

  useEffect(() => {
    sendNativeMessage({
      type: 'languageChanged',
      payload: getCurrentLanguage(),
    });
  }, []);

  return !isSignalHeadlessView ? (
    <>
      <TrainingModeWrapper>
        <Navbar>
          <WebAlertBannerContainer />
          <SmartBannerContainer />
          <Header />
          <Subheader />
          <MainContent id="main-content">
            <Toaster position="top-center" />
            <RoutesContainer />
          </MainContent>
          <Footer />
        </Navbar>
      </TrainingModeWrapper>
    </>
  ) : (
    <MainContent id="main-content">
      <Toaster position="top-center" />
      <RoutesContainer />
    </MainContent>
  );
};

export default Layout;
