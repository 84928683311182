const devicePlatform = () => {
  if (window.accessToken) {
    return 'iOS';
  } else if (
    window?.reactNativeWebView &&
    typeof window?.reactNativeWebView.injectedObjectJson === 'function'
  ) {
    return 'Android';
  } else {
    return 'Web';
  }
};

export const addPlatformPlugin = () => {
  const name = 'add-platform-plugin';
  const type = 'enrichment';
  // eslint-disable-next-line no-unused-vars
  let amplitudeConfig;

  /**
   * setup() is called on plugin installation
   * example: amplitude.add(new AddEventIdPlugin());
   */
  const setup = function (config) {
    amplitudeConfig = config;
  };

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  const execute = function (event) {
    // eslint-disable-next-line no-param-reassign
    event.platform = devicePlatform();
    return event;
  };

  return {
    name,
    type,
    setup,
    execute,
  };
};
