import Constants from 'constants/index';
import i18next from 'i18next';

export const generateTotalTime = (totalMinutes, hour, mins) => {
  const timeSpentHours = Number.isInteger(totalMinutes)
    ? Math.floor(totalMinutes / 60)
    : 0;
  const timeSpentMinutes = Number.isInteger(totalMinutes)
    ? Math.floor(totalMinutes % 60)
    : 0;
  const timeSpentOnPlanString =
    timeSpentHours > 0
      ? `${timeSpentHours} ${hour} ${timeSpentMinutes} ${mins}`
      : `${timeSpentMinutes} ${mins}`;
  return timeSpentOnPlanString;
};

export const generateTotalTimeFromSteps = (steps, hours, mins) => {
  const timeSpentOnPlan = steps
    ?.map(it => it.stepDuration)
    .reduce((acc, a) => acc + a, 0);
  const timeSpentHrs = Number.isInteger(timeSpentOnPlan)
    ? Math.floor(timeSpentOnPlan / 3600)
    : 0;
  const timeSpentMins = Number.isInteger(timeSpentOnPlan)
    ? Math.ceil((timeSpentOnPlan % 3600) / 60)
    : 0;
  const timeSpentOnPlanString =
    timeSpentHrs > 0
      ? `${timeSpentHrs} ${hours} ${timeSpentMins} ${mins}`
      : `${timeSpentMins} ${mins}`;
  return timeSpentOnPlanString;
};

export const getTimeString = time => {
  const timeSpentHrs = Math.floor(time / 60);
  const timeSpentMins = time % 60;
  return `${
    timeSpentHrs > 0 ? timeSpentHrs + ' ' + i18next.t('Generic.hour') : ''
  } ${timeSpentMins} ${i18next.t('Generic.mins')}`;
};

export const getTimeSpentOnPlanForTeamMember = (status, teamMemberId) =>
  status
    ?.find(planStatus => planStatus?.userId === teamMemberId)
    ?.steps?.filter(
      filterStep => filterStep.status === Constants.TRAINING_PLANS.COMPLETE,
    )
    .reduce(
      (acc, step) => acc + Math.round((step?.stepDuration ?? 0) / 60),
      0,
    ) ?? 0;

export const getHrsFromTotalMins = totalMinutes =>
  Math.floor(totalMinutes / 60);

export const getMinsFromTotalMins = totalMinutes => totalMinutes % 60;
