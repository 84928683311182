import { Typography } from 'cfa-react-components';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PlanCardLabel = ({ children, className, 'data-testid': dataTestId }) => {
  return (
    <TypographyLabel
      className={className}
      data-testid={dataTestId}
      variant="overline3"
    >
      {children}
    </TypographyLabel>
  );
};

PlanCardLabel.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

PlanCardLabel.defaultProps = {
  className: '',
  'data-testid': 'PlanCardLabel',
};

const TypographyLabel = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  overflow-wrap: break-word;
`;
export default PlanCardLabel;
