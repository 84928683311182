import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import Constants from 'constants/index';
import PropTypes from 'prop-types';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import { IconPrinter, IconUserPlus } from '@tabler/icons-react';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';

const ManagePlansCompliancePlanCard = ({
  course,
  className,
  'data-testid': dataTestId,
  enrollments,
  onPrintReport,
  onShowEnableCompliancePlan,
  totalTeamMembers,
}) => {
  const { t } = useTranslation();
  const completedUsers = Object.values(enrollments)?.filter(
    enrollment =>
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
      enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED,
  ).length;
  const percentComplete = (completedUsers / totalTeamMembers) * 100;
  return (
    <>
      {!!course && (
        <>
          <PlanCard
            buttons={
              <PopoverMenuButton data-testid="PlanCardMenu">
                <PopoverMenuItemButton
                  data-testid="EnablePlan"
                  icon={<IconUserPlus />}
                  isVisible={!course?.enabled}
                  onClick={() =>
                    onShowEnableCompliancePlan(
                      course?.id,
                      getNameFromLanguage(course?.courseName),
                    )
                  }
                  text={t('Button.enablePlan')}
                />
                <PopoverMenuItemButton
                  data-testid="PrintReport"
                  icon={<IconPrinter />}
                  onClick={() => onPrintReport(course?.id)}
                  text={t('Button.printReport')}
                />
              </PopoverMenuButton>
            }
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            className={className}
            data-testid={dataTestId}
            disabled={!course?.enabled}
            link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${
              course?.id
            }/${
              Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME
            }?compliancePlan=${getNameFromLanguage(course?.courseName)}`}
            progress={percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              <PlanCardSubHeader data-testid="PlanTaskCount">
                {`${completedUsers}/${totalTeamMembers} ${t(
                  'Generic.teamMembersCompleted',
                )}`}
              </PlanCardSubHeader>
            </PlanCardContent>
          </PlanCard>
        </>
      )}
    </>
  );
};

ManagePlansCompliancePlanCard.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  course: PropTypes.shape({
    courseID: PropTypes.string,
    courseName: PropTypes.object,
    createdDate: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.string,
  }).isRequired,
  enrollments: PropTypes.objectOf(
    PropTypes.shape({
      completedDate: PropTypes.string,
      courseId: PropTypes.string,
      dueDate: PropTypes.string,
      duration: PropTypes.string,
      enrolledDate: PropTypes.string,
      enrollmentId: PropTypes.string,
      finalScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      hidden: PropTypes.bool,
      mostRecentCompletedDate: PropTypes.string,
      pathwayCourseId: PropTypes.string,
      percentComplete: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      startedDate: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
  onPrintReport: PropTypes.func.isRequired,
  onShowEnableCompliancePlan: PropTypes.func.isRequired,
  totalTeamMembers: PropTypes.number.isRequired,
};

ManagePlansCompliancePlanCard.defaultProps = {
  className: '',
  'data-testid': 'ManagePlansPlanCard',
};

export default ManagePlansCompliancePlanCard;
