import { Typography } from 'cfa-react-components';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PlanCardTitle = ({ children, className, 'data-testid': dataTestId }) => {
  return (
    <TwoLineMaxTypography
      className={className}
      data-testid={dataTestId}
      fontWeight="bold"
      variant="body1"
    >
      {children}
    </TwoLineMaxTypography>
  );
};

PlanCardTitle.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

PlanCardTitle.defaultProps = {
  className: '',
  'data-testid': 'PlanName',
};

const TwoLineMaxTypography = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
  overflow-wrap: anywhere;
`;
export default PlanCardTitle;
