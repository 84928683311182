export const arrayToCommaString = (arr, translatedAnd) => {
  if (arr.length < 2) {
    return arr.join(', ');
  }
  if (arr.length === 2) {
    return `${arr[0]} ${translatedAnd} ${arr[1]}`;
  }
  return (
    arr.slice(0, -1).join(', ') + ` ${translatedAnd} ` + arr[arr.length - 1]
  );
};
