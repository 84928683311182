import PropTypes from 'prop-types';
import { Tooltip } from 'cfa-react-components';

const PlanCardIcon = ({ icon, tooltip }) => {
  return (
    <>
      {!!tooltip ? (
        <Tooltip
          content={tooltip}
          placement="top"
          showOnElementEvents={['hover']}
        >
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
    </>
  );
};

PlanCardIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PlanCardIcon.defaultProps = {
  tooltip: '',
};

export default PlanCardIcon;
