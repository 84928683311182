import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'cfa-react-components';

export default function EditButtons({
  onCancel,
  onEdit,
  validated,
  primaryButtonText,
}) {
  const { t } = useTranslation();
  return (
    <InlineEditButtons>
      <NoMinWidthButton color="secondary" onClick={onCancel} variant="outlined">
        {t('Button.cancel')}
      </NoMinWidthButton>
      <NoMinWidthButton
        color="secondary"
        data-testid="EditButtonPrimary"
        disabled={!validated}
        onClick={onEdit}
        variant="filled"
      >
        {primaryButtonText ? primaryButtonText : t('Button.update')}
      </NoMinWidthButton>
    </InlineEditButtons>
  );
}

EditButtons.propTypes = {
  primaryButtonText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  validated: PropTypes.bool,
};
EditButtons.defaultProps = {
  primaryButtonText: '',
  validated: false,
};

const NoMinWidthButton = styled(Button)`
  min-width: 0 !important;
`;
const InlineEditButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 16px;
`;
