import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import {
  generateTotalTime,
  getHrsFromTotalMins,
  getMinsFromTotalMins,
  getTimeSpentOnPlanForTeamMember,
} from 'util/time';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  useGetAssignedStatusQuery,
  useGetTeamMembersQuery,
} from 'services/pathwayApi';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import {
  selectAllLocationsWithAtLeastTrainer,
  selectUserLanguage,
} from 'store/user/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import { Tooltip, Typography } from 'cfa-react-components';
import PlanItemCard from '../SharedComponents/PlansItemCards/PlanItemCard';
import PlanItemTask from '../SharedComponents/PlansItemCards/PlanItemTask';
import PlanItemQuiz from '../SharedComponents/PlansItemCards/PlanItemQuiz';
import PlanItemProcedure from '../SharedComponents/PlansItemCards/PlanItemProcedure';

const TeamMemberProgressView = () => {
  const { id, teamMemberId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLanguage = useSelector(selectUserLanguage);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const { data: plan, isFetching: isFetchingPlan } = useGetAssignedStatusQuery({
    checklist: id,
  });
  const { data: users } = useGetTeamMembersQuery({
    locations: locationsWithAtLeastTrainer,
  });
  // eslint-disable-next-line no-shadow
  const user = users?.find(user => user.adId === teamMemberId);
  const name = user?.name;
  const isStepCompleted = stepId => {
    return plan?.status
      .filter(status => status.userId === teamMemberId)
      .every(
        planAssigned =>
          planAssigned?.steps?.find(step => step.stepId === stepId)?.status ===
          Constants.TRAINING_PLANS.COMPLETE,
      );
  };

  const isPlanComplete =
    plan?.status?.find(stat => stat.userId === teamMemberId)?.status ===
    Constants.TRAINING_PLANS.COMPLETED;
  const timeSpentOnPlan = getTimeSpentOnPlanForTeamMember(
    plan?.status,
    teamMemberId,
  );
  const timeSpentHrs = getHrsFromTotalMins(timeSpentOnPlan);
  const timeSpentMins = getMinsFromTotalMins(timeSpentOnPlan);

  useEffect(() => {
    if (plan) {
      dispatch(
        setHeaderLabel(
          t(mapCategoryToAliasTranslation(plan?.checklist?.category)),
        ),
      );
      dispatch(setHeader(getNameFromLanguage(plan?.checklist?.name)));
      if (plan?.checklist?.estimatedMinutes) {
        dispatch(
          setHeaderSubtext(
            t('TrainingPlans.trainingMode.estimatedCompletionTime', {
              estimatedTime: generateTotalTime(
                plan?.checklist?.estimatedMinutes,
                t('Generic.hour'),
                t('Generic.mins'),
              ),
            }),
          ),
        );
      }
    }
    return () => {
      dispatch(setHeaderLabel(null));
      dispatch(setHeader(null));
      dispatch(setHeaderSubtext(null));
    };
  }, [plan, dispatch, t]);
  return (
    <>
      <LoadingOverlay isOpen={isFetchingPlan} />
      <>
        <AssignedPlanHeader>
          <TeamMemberContainer>
            <TypeHeader variant="overline3">
              {t('Generic.teamMember')}
            </TypeHeader>
            <TeamMemberName>{name}</TeamMemberName>
          </TeamMemberContainer>

          <TimeSpentContainer>
            <TypeHeader variant="overline3">
              {isPlanComplete
                ? t('TrainingPlans.accountability.planCompletedIn')
                : t('TrainingPlans.accountability.timeSpentOnPlan')}
            </TypeHeader>
            <Tooltip
              content={t('TrainingPlans.timeSpentOnPlanTooltip')}
              placement="bottom"
              showOnElementEvents={['hover']}
            >
              <TimeSpentText>{`${
                timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
              } ${timeSpentMins} ${t('Generic.mins')}`}</TimeSpentText>
            </Tooltip>
          </TimeSpentContainer>
        </AssignedPlanHeader>
        {plan?.checklist?.steps.map((step, index) => {
          switch (step?.type) {
            case Constants.STEP_TYPES.QUIZ:
              return (
                <PlanItemCard disabled key={index}>
                  <PlanItemQuiz
                    disabled
                    isActive={false}
                    isComplete={isStepCompleted(step.id)}
                    language={userLanguage}
                    onClick={() => {}}
                    onCompleteToggle={() => {}}
                    onLoading={() => {}}
                    onRefetch={() => {}}
                    planId={plan?.checklist?.id}
                    section={null}
                    statuses={plan?.status}
                    step={step}
                    users={[teamMemberId]}
                  />
                </PlanItemCard>
              );
            case Constants.STEP_TYPES.TASK:
              return (
                <PlanItemCard disabled key={index}>
                  <PlanItemTask
                    disabled
                    isActive={false}
                    isComplete={isStepCompleted(step.id)}
                    onClick={() => {}}
                    onCompleteToggle={() => {}}
                    statuses={plan?.status}
                    step={step}
                    user={user}
                  />
                </PlanItemCard>
              );
            case Constants.STEP_TYPES.DOCUMENT:
              return (
                <PlanItemCard disabled key={index}>
                  <PlanItemProcedure
                    disabled
                    isActive={false}
                    isComplete={isStepCompleted(step.id)}
                    isMissing={!step.available}
                    onClick={() => {}}
                    onCompleteToggle={() => {}}
                    statuses={plan?.status}
                    step={step}
                    user={user}
                  />
                </PlanItemCard>
              );
            default:
              return null;
          }
        })}
        {plan?.checklist?.sections.map(section => {
          return (
            !!section.steps.length && (
              <PlanItemCard
                disabled
                key={section?.id}
                sectionName={getNameFromLanguage(section.name)}
              >
                {section.steps.map((step, stepIndex) => {
                  switch (step?.type) {
                    case Constants.STEP_TYPES.QUIZ:
                      return (
                        <PlanItemQuiz
                          disabled
                          isActive={false}
                          isComplete={isStepCompleted(step.id)}
                          key={stepIndex}
                          language={userLanguage}
                          onClick={() => {}}
                          onCompleteToggle={() => {}}
                          onLoading={() => {}}
                          onRefetch={() => {}}
                          planId={plan?.checklist?.id}
                          section={section}
                          statuses={plan?.status}
                          step={step}
                          users={[teamMemberId]}
                        />
                      );
                    case Constants.STEP_TYPES.TASK:
                      return (
                        <PlanItemTask
                          disabled
                          isActive={false}
                          isComplete={isStepCompleted(step.id)}
                          key={stepIndex}
                          onClick={() => {}}
                          onCompleteToggle={() => {}}
                          statuses={plan?.status}
                          step={step}
                          user={user}
                        />
                      );
                    case Constants.STEP_TYPES.DOCUMENT:
                      return (
                        <PlanItemProcedure
                          disabled
                          isActive={false}
                          isComplete={isStepCompleted(step.id)}
                          isMissing={!step.available}
                          key={stepIndex}
                          onClick={() => {}}
                          onCompleteToggle={() => {}}
                          statuses={plan?.status}
                          step={step}
                          user={user}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </PlanItemCard>
            )
          );
        })}
      </>
    </>
  );
};

const AssignedPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px 0;
`;
const TeamMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;
const TimeSpentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;
const TimeSpentText = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const TypeHeader = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
`;
const TeamMemberName = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export default TeamMemberProgressView;
