import { createSelector } from 'reselect';

export const plansFilterState = state => state.plans;

export const selectSortedPaginatedAndFilteredPlans = createSelector(
  plansFilterState,
  state => state.paginatedPlans,
);

export const selectSortedAndFilteredPlans = createSelector(
  plansFilterState,
  state => state.filteredPlans,
);

export const selectSearchFilter = createSelector(
  plansFilterState,
  state => state.searchFilter,
);

export const selectFilters = createSelector(plansFilterState, state => [
  ...state.categoryFilters,
  ...state.statusFilters,
]);

export const selectPlansPagination = createSelector(
  plansFilterState,
  state => ({
    showing: state.showing,
    total: state.total,
  }),
);

export const selectSort = createSelector(plansFilterState, state => state.sort);
