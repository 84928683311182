import Constants from 'constants/index';
import { generateTotalTimeFromSteps } from 'util/time';
import { getNameFromLanguage } from 'util/language';
import { arrayToCommaString } from 'util/arrayToCommaString';
import { removeUnderscoreAndCapitalizeString } from 'util/removeUnderscoreAndCapitalizeString';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { messageReactNative } from 'util/messageReactNative';
import { htmlFromPrintJson } from 'util/PrintJSConversion';
import styled from 'styled-components';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from 'store/header/slice';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components/dist/cjs';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from 'store/managePlansFilter/selector';
import {
  useGetAssignableChecklistsQuery,
  useGetAssignedStatusQuery,
  useGetCourseReportQuery,
  useGetTeamMembersQuery,
  useGetReportsTeamMembersQuery,
  useGetOperatorsQuery,
} from 'services/pathwayApi';
import {
  addManagePlansCategoryFilter,
  clearManagePlansCheckboxFilters,
  clearManagePlansSearchFilter,
  loadMorePlans,
  removeManagePlansFilter,
  setManagePlans,
  setManagePlansSearchFilter,
  setManagePlansSort,
} from 'store/managePlansFilter/slice';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
} from 'store/user/selectors';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import uniqBy from 'lodash/uniqBy';
import print from 'print-js';
import ReportsPlanCard from 'components/PlanCard/PlanCards/ReportsPlanCard';
import ReportsCompliancePlanCard from 'components/PlanCard/PlanCards/ReportsCompliancePlanCard';
import theme from 'styles/theme';
import { NoMessage } from 'containers/TrainingPlans/ManagePlans/ManagePlanView';

const ReportsPlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const currentFilters = useSelector(selectFilters);
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const sort = useSelector(selectSort);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectLocationsWithTrainerPermission,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const searchFilter = useSelector(selectSearchFilter) ?? '';
  const { showing, total } = useSelector(selectPagination);
  const isTrainerOrLeaderOrOperator =
    !!locationsWithAtLeastTrainerPermission.length ||
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;
  const [showChooseLocationsPlanPopup, setShowChooseLocationsPlanPopup] =
    useState(false);
  const [triggerTeamMembersReportRefetch, setTriggerTeamMembersReportRefetch] =
    useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [planDetails, setPlanDetails] = useState({
    category: '',
    id: '',
    isCompliance: false,
    locations: [],
    name: '',
  });
  const [statusReport, setStatusReport] = useState({
    courseId: '',
    courseName: '',
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const [teamMembersReport, setTeamMembersReport] = useState([]);

  const categoryLabels = {
    [Constants.PLAN_CATEGORIES.DEFAULT]: {
      translationString: t('TrainingPlans.planCategories.default'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.DEFAULT),
    },
    [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.backOfHouse'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.BACK_OF_HOUSE),
    },
    [Constants.PLAN_CATEGORIES.COMPLIANCE]: {
      translationString: t('TrainingPlans.planCategories.compliance'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE),
    },
    [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.frontOfHouse'),
      value: !!currentFilters.includes(
        Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE,
      ),
    },
    [Constants.PLAN_CATEGORIES.HOSPITALITY]: {
      translationString: t('TrainingPlans.planCategories.hospitality'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.HOSPITALITY),
    },
    [Constants.PLAN_CATEGORIES.LEADERSHIP]: {
      translationString: t('TrainingPlans.planCategories.leadership'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.LEADERSHIP),
    },
    [Constants.PLAN_CATEGORIES.ONBOARDING]: {
      translationString: t('TrainingPlans.planCategories.onboarding'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.ONBOARDING),
    },
  };

  const sortOptions = [
    {
      id: '1',
      translationString: t('TrainingPlans.filtering.newest'),
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '2',
      translationString: t('TrainingPlans.filtering.oldest'),
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '3',
      translationString: t('TrainingPlans.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '4',
      translationString: t('TrainingPlans.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ];

  // Plans
  const {
    data: unOrderedPlans,
    isFetching,
    isSuccess,
  } = useGetAssignableChecklistsQuery({}, { refetchOnMountOrArgChange: true });

  const { data: operators } = useGetOperatorsQuery();

  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeaderPermission.includes(operatorLocation),
    ),
  );

  // Team Members (for Plan Card)
  const { data: allTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { refetchOnMountOrArgChange: true },
  );

  // Report to print
  const { data: statusReportData, isSuccess: isStatusReportSuccess } =
    useGetCourseReportQuery(
      {
        courseId: planDetails.id,
        location: selectedLocations,
      },
      {
        skip:
          !planDetails.isCompliance ||
          !planDetails.id ||
          !isTrainerOrLeaderOrOperator ||
          !selectedLocations.length,
      },
    );

  // Team Members
  const {
    data: reportsTeamMembersData,
    isSuccess: isReportsTeamMembersDataSuccess,
    refetch: refetchReportsTeamMembers,
  } = useGetReportsTeamMembersQuery({
    refetchOnMountOrArgChange: true,
  });

  const {
    data: assignedTeamMembersList,
    isSuccess: isAssignedTeamMembersListSuccess,
  } = useGetAssignedStatusQuery(
    {
      checklist: planDetails.id,
      location: locationsWithAtLeastTrainer,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planDetails.id || planDetails.isCompliance,
    },
  );

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Set Plans
  useEffect(() => {
    if (isSuccess) {
      const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
        return {
          ...plan,
          planName: getNameFromLanguage(plan.checklist.name).toLowerCase(),
        };
      });
      dispatch(
        setManagePlans({
          plans: storePlansWithName?.concat(unOrderedPlans?.courses) ?? [],
        }),
      );
    }
  }, [dispatch, isSuccess, unOrderedPlans]);

  useEffect(() => {
    if (isStatusReportSuccess) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData]);

  useEffect(() => {
    if (isReportsTeamMembersDataSuccess) {
      setTeamMembersReport(reportsTeamMembersData);
    }
  }, [isReportsTeamMembersDataSuccess, reportsTeamMembersData]);

  useEffect(() => {
    if (triggerTeamMembersReportRefetch) {
      refetchReportsTeamMembers();
    }
  }, [
    isStatusReportSuccess,
    refetchReportsTeamMembers,
    reportsTeamMembersData,
    triggerTeamMembersReportRefetch,
  ]);

  const handlePrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }) => {
    setPlanDetails({
      category,
      id,
      isCompliance: isCompliancePlan,
      locations,
      name: planName,
    });
    isCompliancePlan || locations?.length > 1
      ? setShowChooseLocationsPlanPopup(true)
      : // We don't have the data to print yet so we need to refetch first
        setTriggerTeamMembersReportRefetch(true);
  };

  const translatedName = t('Generic.name');
  const translatedDOB = t('Generic.dateOfBirth');
  const translatedCompletionDate = t('Generic.completionDate');
  const translatedItemsCompleted = t('Generic.itemsCompleted');
  const translatedTotalTime = t('Generic.totalTime');
  const translatedStatus = t('Generic.status');

  const complianceReportToPrint = [...statusReport?.userData]
    .sort((teamMemberA, teamMemberB) => {
      return teamMemberA.firstName.localeCompare(teamMemberB.firstName);
    })
    .map(teamMember => {
      return {
        [translatedName]: `${teamMember.firstName} ${teamMember.lastName}`,
        [translatedDOB]: teamMember.birthDate
          ? teamMember.birthDate
          : t('Generic.na'),
        [translatedCompletionDate]: teamMember.courseCompletionDate
          ? new Date(teamMember.courseCompletionDate).toLocaleDateString()
          : t('Generic.notInCompliance'),
      };
    });

  const reportData = uniqBy(
    teamMembersReport?.users
      ?.map(teamMember => {
        return {
          ...teamMember.user,
          plansAssigned: teamMember.plansAssigned,
          plansCompleted: teamMember.plansCompleted,
        };
      })
      ?.filter(teamMember1 => {
        return assignedTeamMembersList?.status
          .filter(
            teamMember =>
              teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
          )
          .some(teamMember2 => teamMember1.adId === teamMember2.userId);
      })
      .map(user => {
        return {
          ...user,
          completionDate: assignedTeamMembersList?.status.find(
            teamMember => teamMember.userId === user.adId,
          ).completionDate,
          status: assignedTeamMembersList?.status.find(
            teamMember => teamMember.userId === user.adId,
          ).status,
          steps: assignedTeamMembersList?.status.find(
            teamMember => teamMember.userId === user.adId,
          ).steps,
        };
      }),
    'adId',
  );

  const storeReportToPrint = uniqBy(
    reportData.filter(teamMember =>
      planDetails?.locations?.length > 1
        ? teamMember?.locations.some(location =>
            selectedLocations.includes(location),
          )
        : teamMember,
    ),
    'adId',
  )
    .sort((teamMemberA, teamMemberB) => {
      return teamMemberA.name.localeCompare(teamMemberB.name);
    })
    .map(teamMember => {
      return {
        [translatedName]: teamMember.name,
        [translatedItemsCompleted]: `${teamMember.plansCompleted}/${teamMember.plansAssigned}`,
        [translatedTotalTime]: generateTotalTimeFromSteps(
          teamMember.steps,
          t('Generic.hour'),
          t('Generic.mins'),
        ),
        [translatedStatus]: t(
          `TrainingPlans.statusOptions.${
            Constants.TRAINING_PLANS_STATUSES[teamMember.status]
          }`,
        ),
        [translatedCompletionDate]: teamMember.completionDate
          ? new Date(teamMember.completionDate).toLocaleDateString()
          : t('Generic.na'),
      };
    });

  //eslint-disable-next-line
  const reportToPrint = {
    printable: planDetails.isCompliance
      ? complianceReportToPrint
      : storeReportToPrint,
    properties: planDetails.isCompliance
      ? [translatedName, translatedDOB, translatedCompletionDate]
      : [
          translatedName,
          translatedItemsCompleted,
          translatedTotalTime,
          translatedStatus,
          translatedCompletionDate,
        ],
    type: 'json',
    header: `
            <div class="header">
            <span class="category">${removeUnderscoreAndCapitalizeString(
              planDetails?.category ?? '',
            )}</span>
            <span class="courseName">${removeUnderscoreAndCapitalizeString(
              planDetails?.name ?? '',
            )}</span>
            <span class="locations">${t('Generic.at')} ${
      !selectedLocations.length && planDetails?.locations
        ? planDetails?.locations.toString()
        : arrayToCommaString(selectedLocations, t('Generic.and'))
    }</span>
            </div>
            `,
    style: theme.reports.style,
    gridHeaderStyle: theme.reports.gridHeader,
    gridStyle: theme.reports.grid,
  };

  /** If a report does not have a location screen, we need to wait until the data is
   * returned from the query.  Once we have the data from the backend, we can then
   * show the printable report.
   */
  useEffect(() => {
    if (
      triggerTeamMembersReportRefetch &&
      isReportsTeamMembersDataSuccess &&
      isAssignedTeamMembersListSuccess
    ) {
      print(reportToPrint);
      const printHTML = htmlFromPrintJson(reportToPrint);
      messageReactNative(Constants.RN_MESSAGE_TYPES.PRINT, printHTML.innerHTML);
      setTriggerTeamMembersReportRefetch(false);
    }
  }, [
    isAssignedTeamMembersListSuccess,
    isReportsTeamMembersDataSuccess,
    reportToPrint,
    triggerTeamMembersReportRefetch,
  ]);

  const onPrintReport = () => {
    print(reportToPrint);
    const printHTML = htmlFromPrintJson(reportToPrint);
    messageReactNative(Constants.RN_MESSAGE_TYPES.PRINT, printHTML.innerHTML);
    setShowChooseLocationsPlanPopup(false);
    setSelectedLocations([]);
  };

  const onPrintReportCancel = () => {
    setShowChooseLocationsPlanPopup(false);
    setSelectedLocations([]);
  };

  return (
    <>
      <StyledContent>
        <SearchFilterHeader
          onChange={e =>
            dispatch(
              setManagePlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearManagePlansSearchFilter())}
          searchPlaceholder={t('Reports.filtering.searchPlans')}
          searchValue={searchFilter}
          title={t('Reports.tabPlans')}
        />
        {!!isSmAndDown && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setManagePlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              filteredAndSortedPlans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
          >
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlansList>
          {!isSmAndDown && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeManagePlansFilter({ filter: value }));
                  } else {
                    dispatch(addManagePlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
            </StickyFilterCard>
          )}
          <PlansContainer>
            <PlanCardsList>
              <LoadingOverlay isOpen={isFetching} />
              {!!filteredAndSortedPlans?.length && !isFetching && (
                <>
                  <SortFilterHeader
                    label={t('TrainingPlans.filtering.sortBy')}
                    onChange={option => {
                      dispatch(setManagePlansSort({ sort: option.value }));
                    }}
                    onClear={() => {
                      dispatch(clearManagePlansCheckboxFilters());
                    }}
                    options={sortOptions}
                    showClear={false}
                    text={`${total ?? 0} ${t('Reports.tabPlans')}`}
                    value={sortOptions?.find(option => option.value === sort)}
                  />
                  <ReportsCardContainer>
                    {filteredAndSortedPlans.map(plan =>
                      plan?.checklist ? (
                        <ReportsPlanCard
                          assignedUsers={plan?.assignedUsers}
                          completedUsers={plan?.completedUsers}
                          data-testid="ReportsPlanCard"
                          key={plan?.checklist?.id}
                          locations={
                            // intersects locations where user is a leader with the plans locations
                            arrayIntersect(
                              operatorsWhereUserIsLeader?.find(
                                operator =>
                                  operator.id === plan?.checklist?.ownerId,
                              )?.locations ?? [],
                              locationsWithAtLeastLeaderPermission,
                            )
                          }
                          onPrintReport={({ category, id, locations }) =>
                            handlePrintReport({
                              category,
                              id,
                              isCompliancePlan: plan?.courseID,
                              locations,
                              planName: getNameFromLanguage(
                                plan?.checklist?.name,
                              ),
                            })
                          }
                          plan={plan?.checklist}
                        />
                      ) : (
                        <ReportsCompliancePlanCard
                          assignedUsers={plan?.assignedUsers}
                          completedUsers={plan?.completedUsers}
                          course={{
                            courseID: plan?.courseID,
                            courseName: plan?.courseName,
                            createdDate: plan?.createdDate,
                            enabled: plan?.enabled,
                            id: plan?.id,
                          }}
                          data-testid="ReportsCompliancePlanCard"
                          enrollments={plan?.enrollments}
                          key={plan.courseID}
                          locations={locationsWithAtLeastLeaderPermission}
                          onPrintReport={({ category, id, locations }) =>
                            handlePrintReport({
                              category,
                              id,
                              isCompliancePlan: plan?.courseID,
                              locations,
                              planName: getNameFromLanguage(plan?.name),
                            })
                          }
                          totalTeamMembers={
                            allTeamMembersData.filter(
                              (value, innerIndex, self) =>
                                innerIndex ===
                                self.findIndex(
                                  user => user.adId === value.adId,
                                ),
                            ).length
                          }
                        />
                      ),
                    )}
                  </ReportsCardContainer>
                  <LoadMorePaginator
                    onClick={() => dispatch(loadMorePlans())}
                    showing={showing}
                    showingText={t('TrainingPlans.showingXOfYPlans', {
                      showing,
                      total: total ?? 0,
                    })}
                    total={total ?? 0}
                  />
                </>
              )}
              {!filteredAndSortedPlans?.length &&
                !isFetching &&
                !currentFilters?.length &&
                !searchFilter && (
                  <NoMessage
                    message={
                      <Trans i18nKey={'multiline'}>
                        {t('Reports.noPlans')}
                      </Trans>
                    }
                  />
                )}
              {(!!currentFilters?.length || !!searchFilter) && !total && (
                <NoMessage message={t('TrainingPlans.noPlansResults')} />
              )}
            </PlanCardsList>
          </PlansContainer>
        </PlansList>
      </StyledContent>
      <ConfirmationModal
        bodyText={t('TrainingPlans.whichLocationsPrintReport')}
        children={
          planDetails?.locations
            ? planDetails?.locations?.map((id, idx) => (
                <CheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedLocations}
                  setSelectedLocations={setSelectedLocations}
                />
              ))
            : // Compliance plans
              locationsWithAtLeastTrainer.map((id, idx) => (
                <CheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedLocations}
                  setSelectedLocations={setSelectedLocations}
                />
              ))
        }
        headerText={t('Generic.chooseLocation')}
        isDisabled={!selectedLocations?.length}
        isOpen={showChooseLocationsPlanPopup}
        onClose={onPrintReportCancel}
        primaryButtonHandler={onPrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

const ReportsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

export default ReportsPlansTab;
