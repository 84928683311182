import { isAccessDeniedError } from 'util/error';
import Constants from 'constants/index';
import { LoginCallback } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { useAmplitudeTrack } from 'amplitude/useAmplitude';
import GenericError from '../sharedComponents/app/GenericError';

const CustomLoginCallback = () => {
  /*  const { oktaAuth: okauth } = useOktaAuth();
    okauth.tokenManager.on('expired', () => authClient.tokenManager.renew('accessToken'));
  */

  const { t } = useTranslation();
  const track = useAmplitudeTrack();

  useEffect(() => {
    if (track !== null) {
      track('LoginSuccess', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);
  const RenderError = ({ error }) => {
    return isAccessDeniedError(error) ? (
      <ConfirmationModal
        headerText={t('UserNotAssignedToAppError.errorHeader')}
        isError={true}
        isOpen={true}
        onClose={() => (window.location.href = Constants.CFA_HOME_URL)}
        paragraphText={t('UserNotAssignedToAppError.errorParagraph')}
        primaryButtonHandler={() =>
          (window.location.href = Constants.CFA_HOME_URL)
        }
        primaryButtonText={t('Button.returnToCfa')}
      />
    ) : (
      <GenericError />
    );
  };
  RenderError.propTypes = {
    error: PropTypes.object.isRequired,
  };
  return <LoginCallback errorComponent={RenderError} />;
};

export default CustomLoginCallback;
