import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import { formatBugsnagErrorMessage } from 'bugsnag';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Bugsnag from '@bugsnag/browser';
import {
  useGetOperatorsQuery,
  useGetQuizzesQuery,
  useUpdateQuizMutation,
  useDeleteQuizMutation,
} from 'services/pathwayApi';
import { setBuildQuizzes, loadMoreQuizzes } from 'store/quizzes/slice';
import {
  selectPaginatedQuizzes,
  selectPagination,
} from 'store/quizzes/selector';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import { Row, Col } from 'cfa-react-components';
import GenericError from 'sharedComponents/app/GenericError';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import AddQuizPopUp from 'sharedComponents/app/popups/AddQuizPopUp';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { withRoles } from 'sharedComponents/app/withRoles';
import { Fab } from 'sharedComponents/app/Fab/Fab';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from 'store/header/slice';
import {
  selectUserIsStaff,
  selectAllLocationsWithAtLeastLeaderPermissions,
} from 'store/user/selectors';
import { toast } from 'react-hot-toast';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { NoMessage } from '../ManagePlans/ManagePlanView';
import QuizCard from './QuizCard/QuizCard';

const BuildQuizzesTab = () => {
  const { t } = useTranslation();
  const userIsStaff = useSelector(selectUserIsStaff);
  const { showing, total } = useSelector(selectPagination);
  const paginatedQuizzes = useSelector(selectPaginatedQuizzes);
  const history = useHistory();
  const dispatch = useDispatch();
  const [updateQuiz] = useUpdateQuizMutation();
  const [deleteQuiz] = useDeleteQuizMutation();
  const [quiz, setQuiz] = useState({});
  const [showCreateQuizPopUp, setShowCreateQuizPopUp] = useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);

  const locationsWithAtLeastLeader = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );

  const {
    data: operators,
    isFetching: isFetchingOperators,
    error: errorGettingOperators,
  } = useGetOperatorsQuery();

  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeader.includes(operatorLocation),
    ),
  );

  const {
    data: quizzes,
    isFetching: isFetchingQuizzes,
    error: errorGettingQuizzes,
    refetch: refetchGetQuizzesQuery,
  } = useGetQuizzesQuery({}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    dispatch(setHeader(t('TrainingPlans.tabPlans')));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(setBuildQuizzes({ quizzes: quizzes ?? [] }));
  }, [dispatch, quizzes]);

  const isValidQuizName = (quizName, operator) => {
    if (!quizName || (!operator && !userIsStaff)) {
      setShowInvalidNameError(true);
      console.error('invalid quiz name or missing operator');
      Bugsnag.notify(
        new Error(
          `Build quizzes error - no quiz name or no operator: ${JSON.stringify({
            quizName,
            operator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  const onAddQuiz = (operator, quizName) => {
    const trimmedName = quizName?.trim();
    if (!isValidQuizName(trimmedName, operator)) {
      return;
    }
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
      state: { operator, quizName },
    });
  };

  const onRenameQuiz = (newName, quizDetails) => {
    const trimmedName = newName?.trim();
    if (!isValidQuizName(trimmedName, quizDetails.operatorId)) {
      return;
    }
    const payload = {
      ...quiz,
      name: {
        ...quiz.name,
        en: trimmedName,
      },
    };
    updateQuiz(payload)
      .unwrap()
      .then(() => {
        refetchGetQuizzesQuery();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.renamed')}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onSaveTranslations = updatedTranslations => {
    updateQuiz(updatedTranslations)
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(updatedTranslations?.name)} ${t(
              'TrainingPlans.translationToastText',
            )} ${Constants.LANGUAGE_OPTIONS.SPANISH}`}
          </ToastMessageBlock>
        ));
        refetchGetQuizzesQuery();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onDeleteQuiz = (quizId, quizName) => {
    deleteQuiz(quizId)
      .unwrap()
      .then(() => {
        refetchGetQuizzesQuery();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${quizName} ${t('TrainingPlans.toastMessage.deleted')}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  if (isApiError(errorGettingOperators || errorGettingQuizzes)) {
    const error = errorGettingOperators || errorGettingQuizzes;
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  return (
    <>
      <StyledContent>
        <LoadingOverlay isOpen={isFetchingOperators || isFetchingQuizzes} />
        {!!paginatedQuizzes?.length && !isFetchingQuizzes && (
          <StyledListResults className="list-results-no-pad">
            {paginatedQuizzes.map((quizDetails, idx) => (
              <StyledRow key={idx}>
                <Col>
                  <QuizCard
                    key={idx}
                    onDeleteQuiz={onDeleteQuiz}
                    onRenameQuiz={newName => onRenameQuiz(newName, quizDetails)}
                    onSaveTranslations={onSaveTranslations}
                    quizDetails={quizDetails}
                    setQuiz={setQuiz}
                  />
                </Col>
              </StyledRow>
            ))}
            <LoadMorePaginator
              onClick={() => dispatch(loadMoreQuizzes())}
              showing={showing}
              showingText={t('TrainingPlans.showingXOfYQuizzes', {
                showing,
                total,
              })}
              total={total}
            />
          </StyledListResults>
        )}
        {!paginatedQuizzes?.length && !isFetchingQuizzes && (
          <NoMessage
            message={
              <Trans i18nKey={'multiline'}>
                {t('TrainingPlans.buildQuizzes.noQuizzesLoaded')}
              </Trans>
            }
          />
        )}
        {(!!operators?.length || !!userIsStaff) && (
          <>
            <Fab
              hasMenu={false}
              isOpen={showCreateQuizPopUp}
              onClick={() => setShowCreateQuizPopUp(true)}
              tooltip={t('TrainingPlans.buildQuizzes.createQuizHover')}
            />
            <AddQuizPopUp
              open={showCreateQuizPopUp}
              operators={operatorsWhereUserIsLeader}
              setOpen={setShowCreateQuizPopUp}
              submitHandler={({ operator, quizNameInput }) => {
                setShowCreateQuizPopUp(false);
                onAddQuiz(operator, quizNameInput);
              }}
            />
          </>
        )}
      </StyledContent>
      <ConfirmationModal
        bodyText={t('InvalidQuizName.paragraphText')}
        headerText={t('InvalidQuizName.errorHeader')}
        isError={true}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};

const StyledContent = styled.div`
  height: 100%;
  position: relative;
`;

const StyledListResults = styled.div``;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
`;

export default withRoles(BuildQuizzesTab, [
  Constants.USER_PERMISSIONS.LEADER,
  Constants.USER_PERMISSIONS.OPERATOR,
]);
