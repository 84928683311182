import { isApiError } from 'util/request';
import Constants from 'constants/index';
import { getNameFromLanguage, pluralize } from 'util/language';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from 'i18n/language';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLoadMore } from 'hooks/useLoadMore';
import {
  pathwayApi,
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import Bugsnag from '@bugsnag/browser';
import GenericError from 'sharedComponents/app/GenericError';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatBugsnagErrorMessage } from 'bugsnag';
import StepWizard from 'react-step-wizard';
import { setSubcategoryId } from 'store/subcategory/slice';
import { v4 as uuidv4 } from 'uuid';
import CategoryCard from 'containers/Explore/Operations/components/CategoryCard';
import CategorySection from 'containers/Explore/Operations/components/CategorySection';
import SubCategoryAccordion from 'containers/Explore/Operations/components/SubCategoryAccordian';
import SubCategoryDocument from 'containers/Explore/Operations/components/SubCategoryDocument';
import {
  Button,
  Card,
  IconButton,
  Modal,
  ModalBody,
  Typography,
} from 'cfa-react-components';
import {
  IconArrowLeft,
  IconSquareRoundedCheckFilled,
  IconSquareRoundedPlus,
} from '@tabler/icons-react';
import ScrollModalFooter from 'components/ScrollModal/ScrollModalFooter';
import ScrollModalHeader from 'components/ScrollModal/ScrollModalHeader';
import Searchbar from 'components/Searchbar/Searchbar';
import PageHeader from 'components/PageHeader/PageHeader';

const AddProcedurePopUp = props => {
  const {
    isOpen,
    onClose,
    plan,
    refetch,
    isInSection,
    sectionId,
    onAdminAddProcedure,
    adminAddingProcedure,
  } = props;
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const { data, error } = useGetCategoriesQuery();
  const categories = data?.categories ?? [];
  const featured = data?.featured ?? [];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const searchInputRef = useRef(null);
  const modalBodyRef = useRef();
  const [categorySelected, setCategorySelected] = useState(null);
  const [activeIndexStep, setActiveIndexStep] = useState(1);
  const [stepWizard, setStepWizard] = useState();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [searchTermDisplayed, setSearchTermDisplayed] = useState('');
  const [searchTermQuery, setSearchTermQuery] = useState('');

  const handleAddAdminProcedure = () => {
    onAdminAddProcedure(selectedDocuments);
    setSelectedDocuments([]);
  };

  const onAddProcedure = () => {
    const mappedUuidToSelectedDocuments = selectedDocuments.map(
      selectedDocument => {
        return { ...selectedDocument, id: uuidv4() };
      },
    );
    const newSteps = [...plan.steps, ...mappedUuidToSelectedDocuments];
    const payload = {
      locations: plan.locations,
      checklist: isInSection
        ? {
            ...plan,
            sections: [
              ...plan.sections.map(section =>
                section.id === sectionId
                  ? {
                      ...section,
                      steps: [
                        ...section.steps,
                        ...mappedUuidToSelectedDocuments,
                      ],
                    }
                  : section,
              ),
            ],
          }
        : { ...plan, steps: newSteps },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose();
        setSelectedDocuments([]);
      });
  };

  const onClearSearchClick = () => {
    setSearchTermDisplayed('');
    searchInputRef.current.focus();
  };

  const onSearchInputChange = e => {
    const searchTerm = e.target.value;
    searchTerm.length
      ? setSearchTermDisplayed(searchTerm)
      : setSearchTermDisplayed('');
  };

  const onSearchTermSelect = (suggestion, spellingSuggestion) => {
    setActiveIndexStep(2);
    if (spellingSuggestion) {
      setSearchTermDisplayed(spellingSuggestion);
      setSearchTermQuery(spellingSuggestion);
      return;
    }
    if (suggestion) {
      setSearchTermDisplayed(suggestion);
      setSearchTermQuery(suggestion);
      return;
    }
    const searchTerm = searchTermDisplayed?.trim();
    if (searchTerm?.length) {
      setSearchTermDisplayed(searchTerm);
      setSearchTermQuery(searchTerm);
    }
  };

  const currentCount = selectedDocuments.length;

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const onBackButtonClick = () => {
    if (searchTermQuery) {
      setSearchTermQuery('');
      setSearchTermDisplayed('');
      setActiveIndexStep(1);
      return;
    }
    activeIndexStep === 2 ? stepWizard.previousStep() : onClose();
  };

  const handleOnClose = () => {
    onClose();
    setSelectedDocuments([]);
  };

  return (
    <Modal
      onClose={handleOnClose}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ScrollModalHeader scrollRef={modalBodyRef}>
        <ModalHeaderRow>
          {activeIndexStep > 1 ? (
            <IconButton data-testid="BackButton" onClick={onBackButtonClick}>
              <IconArrowLeft />
            </IconButton>
          ) : (
            <HeaderSpacer />
          )}
          {t('TrainingPlans.addResource.title')} ({currentCount})
          <HeaderSpacer />
        </ModalHeaderRow>
        <AddProcedureSearchbar
          elevation={1}
          fullWidth
          onChange={onSearchInputChange}
          onClear={onClearSearchClick}
          onSubmit={onSearchTermSelect}
          placeholder={t('TrainingPlans.addResource.placeholder')}
          ref={searchInputRef}
          searchValue={searchTermDisplayed}
          showRecentSearchDropdown
        />
      </ScrollModalHeader>
      <ModalBody ref={modalBodyRef}>
        <StyledProceduresWrapper>
          {searchTermQuery ? (
            <SearchResults
              onSearchTermSelect={onSearchTermSelect}
              searchTermQuery={searchTermQuery}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
          ) : (
            <StepWizard
              instance={setStepWizard}
              isLazyMount
              onStepChange={({ activeStep }) => setActiveIndexStep(activeStep)}
              transitions={{
                enterRight: '',
                enterLeft: '',
                exitRight: '',
                exitLeft: '',
              }}
            >
              <Step1
                categories={categories}
                featured={featured}
                setActiveIndexStep={setActiveIndexStep}
                setCategorySelected={setCategorySelected}
                setSearchTermDisplayed={setSearchTermDisplayed}
                stepWizard={stepWizard}
              />
              <Step2
                categorySelected={categorySelected}
                selectedDocuments={selectedDocuments}
                setSearchTermDisplayed={setSearchTermDisplayed}
                setSelectedDocuments={setSelectedDocuments}
                setSubcategory={id =>
                  dispatch(
                    setSubcategoryId({
                      id,
                    }),
                  )
                }
                stepWizard={stepWizard}
              />
            </StepWizard>
          )}
        </StyledProceduresWrapper>
      </ModalBody>
      <ScrollModalFooter scrollRef={modalBodyRef}>
        <Button
          color="secondary"
          data-testid="addProcedureButton"
          disabled={currentCount === 0}
          onClick={
            adminAddingProcedure ? handleAddAdminProcedure : onAddProcedure
          }
        >
          {t('Button.add')}
        </Button>
      </ScrollModalFooter>
    </Modal>
  );
};

const Step1 = props => {
  const {
    setCategorySelected,
    categories,
    featured,
    stepWizard,
    setSearchTermDisplayed,
  } = props;
  return (
    <>
      {featured?.length && (
        <CategorySection data-testid="featuredWrapper" section="featured">
          {featured.map(item => (
            <CategoryCard
              categoryIcon={item.icon}
              categoryId={item.id}
              categoryName={item.name}
              key={item.id}
              onClick={() => {
                stepWizard.nextStep();
                setCategorySelected(item.id);
                setSearchTermDisplayed('');
              }}
            />
          ))}
        </CategorySection>
      )}
      {categories?.length && (
        <CategorySection data-testid="featuredWrapper" section="Catgories">
          {categories.map(item => (
            <CategoryCard
              categoryIcon={item.icon}
              categoryId={item.id}
              categoryName={item.name}
              key={item.id}
              onClick={() => {
                stepWizard.nextStep();
                setCategorySelected(item.id);
                setSearchTermDisplayed('');
              }}
            />
          ))}
        </CategorySection>
      )}
    </>
  );
};

const Step2 = props => {
  const { categorySelected, selectedDocuments, setSelectedDocuments } = props;
  const { data, isFetching } = useGetSubCategoriesQuery(
    categorySelected ? categorySelected : '',
  );
  const categoryName = getNameFromLanguage(data?.name);

  const subcategories = data?.subcategories ?? [];
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      label: t('Browse.categories'),
    },
    {
      label: categoryName ?? '',
    },
  ];

  if (isFetching) {
    return 'Loading...';
  }
  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs} title={categoryName} />
      {subcategories?.map((subcategory, index) => (
        <SubCategoryAccordion
          data-testid={`SubcategoryAccordion${index}`}
          key={subcategory?.id ?? index}
          name={getNameFromLanguage(subcategory.name)}
        >
          {subcategory?.subtitles?.map(
            // eslint-disable-next-line no-shadow
            ({ name: sectionName, documents }, index) => (
              <div key={index}>
                <Typography variant="overline3">
                  {getNameFromLanguage(sectionName)}
                </Typography>
                {documents?.map(
                  // eslint-disable-next-line no-shadow
                  (document, index) => (
                    <DocumentContainer key={index}>
                      <SubCategoryDocument
                        icon={
                          getNameFromLanguage(document?.references)?.icon ||
                          'Chickfila'
                        }
                        id={
                          getNameFromLanguage(document?.references)
                            ?.documentId ?? ''
                        }
                        name={
                          getNameFromLanguage(document?.references)?.name ?? ''
                        }
                      />
                      {(() => {
                        const isSelected = selectedDocuments.find(
                          selectedDocument =>
                            selectedDocument.id === document.id,
                        );
                        const onChangeCheckbox = () => {
                          isSelected
                            ? setSelectedDocuments(
                                selectedDocuments.filter(
                                  selectedDocument =>
                                    selectedDocument.id !== document.id,
                                ),
                              )
                            : setSelectedDocuments([
                                ...selectedDocuments,
                                {
                                  ...document,
                                  type: Constants.STEP_TYPES.DOCUMENT,
                                  reference: {
                                    id: document.id,
                                  },
                                },
                              ]);
                        };

                        return (
                          <StyledDocumentCheckbox
                            isSelected={isSelected}
                            onChangeCheckbox={onChangeCheckbox}
                          />
                        );
                      })()}
                    </DocumentContainer>
                  ),
                )}
              </div>
            ),
          )}
        </SubCategoryAccordion>
      ))}
    </>
  );
};

const StyledDocumentCheckbox = props => {
  const { isSelected, onChangeCheckbox } = props;
  return (
    <StyledDocumentCheckboxWrapper>
      <StyledProcedureBoxFiled>
        {isSelected ? (
          <StyledIconSquareRoundedCheckFilled
            data-testid="documentCheckboxSelected"
            onClick={onChangeCheckbox}
          />
        ) : (
          <StyledIconSquareRoundedPlus
            data-testid="documentCheckbox"
            onClick={onChangeCheckbox}
          />
        )}
      </StyledProcedureBoxFiled>
    </StyledDocumentCheckboxWrapper>
  );
};

export const SearchResults = props => {
  const {
    searchTermQuery,
    onSearchTermSelect,
    selectedDocuments,
    setSelectedDocuments,
  } = props;
  const { t } = useTranslation();
  const languageCode = getCurrentLanguage();
  const {
    result,
    isFetching,
    error: fetchingError,
  } = useLoadMore(
    pathwayApi.endpoints.getSearchResults,
    searchTermQuery,
    languageCode,
  );

  if (isApiError(fetchingError)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(fetchingError));
    return <GenericError />;
  }

  return (
    <>
      <LoadingOverlay isOpen={isFetching && result?.data?.length === 0} />
      {result.showingResults && !isFetching ? (
        <>
          {result.misspellingSuggestion && (
            <StyledMisspellingSuggestionWrapper data-testid="MisspellingSuggestionWrapper">
              {t('Search.showingResultsSuggestion')}
              {': '}
              <StyledMisspellingSuggestion
                data-testid="MisspellingSuggestion"
                onClick={() =>
                  onSearchTermSelect(null, result.misspellingSuggestion)
                }
              >
                {result.misspellingSuggestion}
              </StyledMisspellingSuggestion>
            </StyledMisspellingSuggestionWrapper>
          )}
          <StyledResultsText variant="body1">
            {` ${result.numFound} ${t('Search.numberOfResultsFor')} `}
            {<strong>{searchTermQuery}</strong>}
          </StyledResultsText>
        </>
      ) : (
        <StyledResultsText variant="body1">
          {` ${result.numFound} ${t('Search.numberOfResultsFor')} `}
          {<strong>{searchTermQuery}</strong>}
        </StyledResultsText>
      )}
      <div className="list-results">
        {!result?.data?.length ? (
          <StyledNoResults>{t('Search.noResults')}</StyledNoResults>
        ) : (
          result.data?.map(
            ({
              documentId,
              name: documentName,
              icon: documentIcon,
              languages: documentLanguages,
            }) => (
              <StyledCard key={documentId} title={documentName}>
                <SubCategoryDocument
                  icon={
                    documentIcon === 'placeholder' || !documentIcon
                      ? 'Chickfila'
                      : documentIcon
                  }
                  id={documentId}
                  name={documentName}
                  openInNewTab
                />
                {(() => {
                  const isSelected = selectedDocuments.find(
                    selectedDocument => selectedDocument.id === documentId,
                  );
                  const onChangeCheckbox = () => {
                    isSelected
                      ? setSelectedDocuments(
                          selectedDocuments.filter(selectedDocument => {
                            return selectedDocument.id !== documentId;
                          }),
                        )
                      : setSelectedDocuments([
                          ...selectedDocuments,
                          {
                            id: documentId,
                            type: Constants.STEP_TYPES.DOCUMENT,
                            reference: {
                              id: documentId,
                            },
                            name: {
                              en:
                                documentLanguages === 'en'
                                  ? documentName
                                  : null,
                              es:
                                documentLanguages === 'es'
                                  ? documentName
                                  : null,
                            },
                          },
                        ]);
                  };
                  return (
                    <StyledDocumentCheckbox
                      isSelected={isSelected}
                      onChangeCheckbox={onChangeCheckbox}
                    />
                  );
                })()}
              </StyledCard>
            ),
          )
        )}
      </div>
      {!isFetching && result.hasNextPage && (
        <StyledLoadMore
          color="secondary"
          data-testid="LoadMore"
          onClick={() =>
            result.fetchNextPage(
              Math.trunc(
                result.showingResults / Constants.DEFAULT_PAGING_SIZE,
              ) + 1,
            )
          }
        >
          {t('Button.loadMore')}
        </StyledLoadMore>
      )}
      {result.showingResults && !isFetching && (
        <Typography variant="body1">
          {t('Search.showingResultsFooter', {
            showingResults: result.showingResults,
            numberOfResults: result.numFound <= 100 ? result.numFound : '100+',
            resultsText: pluralize(
              result.numFound,
              t('Search.resultText'),
              t('Search.resultsText'),
            ),
          })}
        </Typography>
      )}
    </>
  );
};

const ModalHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSpacer = styled.div`
  width: 24px;
`;

const StyledIconSquareRoundedCheckFilled = styled(IconSquareRoundedCheckFilled)`
  color: ${props => props.theme.semanticColors.success};
  cursor: pointer;
`;

const StyledIconSquareRoundedPlus = styled(IconSquareRoundedPlus)`
  color: ${props => props.theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLoadMore = styled(Button)`
  margin-top: 24px;
`;

const StyledMisspellingSuggestionWrapper = styled.span`
  display: block;
  font-weight: normal;
  color: ${props => props.theme.grayScale.gray7};
  margin-bottom: 16px;
`;
const StyledMisspellingSuggestion = styled.strong`
  color: ${props => props.theme.primaryPalette.navyBlue};
  font-weight: 700;
  cursor: pointer;
`;

const StyledResultsText = styled(Typography)`
  text-align: left;
`;

const StyledNoResults = styled.div`
  padding: 32px 24px;
`;

const AddProcedureSearchbar = styled(Searchbar)`
  margin-top: 24px;
  margin-bottom: 8px;
  z-index: 5;
`;

const StyledCard = styled(Card)`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledProceduresWrapper = styled.div`
  margin: 16px 0;
`;

const StyledDocumentCheckboxWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
const StyledProcedureBoxFiled = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0 1em;
`;

AddProcedurePopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  isInSection: PropTypes.bool,
  sectionId: PropTypes.string,
  onAdminAddProcedure: PropTypes.func,
  adminAddingProcedure: PropTypes.bool,
};

AddProcedurePopUp.defaultProps = {
  isInSection: false,
  sectionId: '',
  onAdminAddProcedure: () => {},
  adminAddingProcedure: false,
};

Step1.propTypes = {
  setCategorySelected: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  featured: PropTypes.array.isRequired,
  stepWizard: PropTypes.object,
  setSearchTermDisplayed: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  stepWizard: {},
};

Step2.propTypes = {
  categorySelected: PropTypes.string,
  selectedDocuments: PropTypes.array.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  categorySelected: '',
};

StyledDocumentCheckbox.propTypes = {
  isSelected: PropTypes.object,
  onChangeCheckbox: PropTypes.func.isRequired,
};

StyledDocumentCheckbox.defaultProps = {
  isSelected: null,
};

SearchResults.propTypes = {
  searchTermQuery: PropTypes.string.isRequired,
  onSearchTermSelect: PropTypes.func.isRequired,
  selectedDocuments: PropTypes.array.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
};

export default AddProcedurePopUp;
