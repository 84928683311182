import { mapCategoryToColor, mapCategoryToIcon } from 'util/categoryUtils';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'styles/theme';

const PlanCardCategoryIcon = ({ category, disabled }) => {
  return (
    <ProgressCardImageWrapper
      $backgroundColor={mapCategoryToColor(category)}
      $isDisabled={disabled}
    >
      {mapCategoryToIcon(category)}
    </ProgressCardImageWrapper>
  );
};

PlanCardCategoryIcon.propTypes = {
  category: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

PlanCardCategoryIcon.defaultProps = {
  disabled: false,
};

const ProgressCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: normal;
  justify-content: center;
  width: 80px;
  height: auto;
  background: ${({ $backgroundColor, $isDisabled }) =>
    $isDisabled ? theme.grayScale.gray3 : $backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
`;

export default PlanCardCategoryIcon;
