import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  TextField,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useState } from 'react';

const InputDialog = ({
  bodyText,
  headerText,
  isDuplicatePlan,
  isOpen,
  onClose,
  secondaryButtonHandler,
  secondaryButtonText,
  primaryButtonHandler,
  primaryButtonText,
  primaryButtonVariant,
  keyDownEnter,
  textInputDefaultValue,
  textInputPlaceholder,
}) => {
  const [textInputValue, setTextInputValue] = useState();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  return (
    <Modal onClose={onClose} show={isOpen} size="md">
      <>
        <StyledModalHeader>{headerText}</StyledModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: bodyText }} />
          <TextField
            autoFocus={true}
            data-testid="ModalInput"
            defaultValue={textInputDefaultValue}
            fullWidth={true}
            onChange={e => setTextInputValue(e.target.value)}
            onKeyDown={e =>
              keyDownEnter &&
              e.key === 'Enter' &&
              primaryButtonHandler(textInputValue)
            }
            placeholder={textInputPlaceholder}
          />
        </ModalBody>
        <ModalFooter>
          <DialogButton
            $isDesktop={!isSmAndDown}
            color="secondary"
            data-testid="ConfirmationPopUpSecondaryButton"
            onClick={secondaryButtonHandler}
            variant="outlined"
          >
            {secondaryButtonText}
          </DialogButton>
          <DialogButton
            $isDesktop={!isSmAndDown}
            color="secondary"
            data-testid="ConfirmationPopUpPrimaryButton"
            disabled={
              !isDuplicatePlan &&
              (!textInputValue || textInputValue === textInputDefaultValue)
            }
            onClick={() =>
              primaryButtonHandler(textInputValue ?? textInputDefaultValue)
            }
            variant={primaryButtonVariant}
          >
            {primaryButtonText}
          </DialogButton>
        </ModalFooter>
      </>
    </Modal>
  );
};

const DialogButton = styled(Button)`
  ${({ $isDesktop }) => $isDesktop && 'flex: 1;'}
`;

InputDialog.propTypes = {
  bodyText: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  isDuplicatePlan: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  primaryButtonHandler: PropTypes.func,
  primaryButtonText: PropTypes.string.isRequired,
  primaryButtonVariant: PropTypes.string,
  secondaryButtonHandler: PropTypes.func,
  secondaryButtonText: PropTypes.string.isRequired,
  keyDownEnter: PropTypes.bool,
  textInputDefaultValue: PropTypes.string,
  textInputPlaceholder: PropTypes.string,
};

InputDialog.defaultProps = {
  bodyText: '',
  isDuplicatePlan: false,
  keyDownEnter: true,
  primaryButtonHandler: null,
  primaryButtonVariant: 'filled',
  secondaryButtonHandler: null,
  textInputDefaultValue: '',
  textInputPlaceholder: '',
};

const StyledModalHeader = styled(ModalHeader)`
  font-size: 24px;
`;

export default InputDialog;
