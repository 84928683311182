import Constants from 'constants/index';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { pathwayApi } from 'services/pathwayApi';
import appReducer from './app/slice';
import chooseLocationsReducer from './chooseLocations/slice';
import headerReducer from './header/slice';
import sideBarReducer from './sideBar/slice';
import searchReducer from './search/slice';
import userReducer from './user/slice';
import printReportReducer from './printReport/slice';
import threeDotMenuReducer from './threeDotMenu/slice';
import trainingModeReducer from './trainingMode/slice';
import manageTranslationsReducer from './manageTranslations/slice';
import plansReducer from './reports/slice';
import subcategoryReducer from './subcategory/slice';
import subheaderReducer from './subheader/slice';
import scrollPositionReducer from './scrollPosition/slice';
import adminReducer from './admin/slice';
import buildPlansFilterReducer from './buildPlansFilter/slice';
import myPlansFilterReducer from './myPlansFilter/slice';
import managePlansFilterReducer from './managePlansFilter/slice';
import teamMembersFilterReducer from './teamMembersFilter/slice';
import quizzesReducer from './quizzes/slice';
import nativeOptionsReducer from './native/slice';
import webAlertBannerReducer from './webAlertBanner/slice';

// TODO: get search history initial redcr. state from slice
const initialReducerState = {};

const persistConfig = {
  key: 'root',
  version: Constants.REDUX_STORE_VERSION,
  storage,
  whitelist: ['search', 'trainingMode', 'user', 'webAlertBanner'],
  migrate: createMigrate({
    [Constants.REDUX_STORE_VERSION]: () => initialReducerState,
  }),
};

const rootReducer = combineReducers({
  app: appReducer,
  buildPlansFilter: buildPlansFilterReducer,
  chooseLocations: chooseLocationsReducer,
  header: headerReducer,
  managePlansFilter: managePlansFilterReducer,
  myPlansFilter: myPlansFilterReducer,
  teamMembersFilter: teamMembersFilterReducer,
  manageTranslations: manageTranslationsReducer,
  printReport: printReportReducer,
  plans: plansReducer,
  scrollPosition: scrollPositionReducer,
  search: searchReducer,
  sideBar: sideBarReducer,
  subcategory: subcategoryReducer,
  subheader: subheaderReducer,
  threeDotMenu: threeDotMenuReducer,
  trainingMode: trainingModeReducer,
  user: userReducer,
  quizzes: quizzesReducer,
  admin: adminReducer,
  nativeOptions: nativeOptionsReducer,
  webAlertBanner: webAlertBannerReducer,
  [pathwayApi.reducerPath]: pathwayApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(pathwayApi.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export default store;
