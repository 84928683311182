import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import Constants from 'constants/index';
import { getDueDateColor, getDueDateString } from 'util/dueDate';
import PropTypes from 'prop-types';
import PlanCard from 'components/PlanCard/PlanCard';
import { useTranslation } from 'react-i18next';
import { Ribbon } from 'icons';
import styled from 'styled-components';
import PlanCardIcon from 'components/PlanCard/PlanCardComponents/PlanCardIcon';
import PlanCardTag from 'components/PlanCard/PlanCardComponents/PlanCardTag';
import PlanCardContent from 'components/PlanCard/PlanCardContent/PlanCardContent';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';

const MyPlansCompliancePlanCard = ({
  className,
  'data-testid': dataTestId,
  course,
}) => {
  const { t } = useTranslation();
  const isComplete =
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED;

  return (
    <>
      {!!course && (
        <>
          <PlanCard
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            className={className}
            data-testid={dataTestId}
            link={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${course?.pathwayCourseId}/true`}
            progress={course?.percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              <PlanCardSubHeader data-testid="PlanTaskCount">
                {t('Generic.percentCompleted', {
                  percent: course?.percentComplete,
                })}
              </PlanCardSubHeader>
            </PlanCardContent>
            {!isComplete && !!course?.dueDate && (
              <PlanCardTag
                color={getDueDateColor(course?.dueDate)}
                data-testid="DateTag"
                text={getDueDateString(course?.dueDate)}
                tooltip={t('TrainingPlans.accountability.due.dueDate', {
                  dueDate: new Date(course?.dueDate).toLocaleDateString(),
                })}
              />
            )}
            {!!isComplete && (
              <PlanCardIcon
                icon={<CompleteRibbon />}
                tooltip={
                  <>
                    <strong>
                      {t('TrainingPlans.statusOptions.completed')}:
                    </strong>
                    {new Date(course?.completedDate).toLocaleDateString()}
                  </>
                }
              />
            )}
          </PlanCard>
        </>
      )}
    </>
  );
};

MyPlansCompliancePlanCard.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  course: PropTypes.shape({
    courseName: PropTypes.object,
    createdDate: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.string,
    completedDate: PropTypes.string,
    dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    enrolledDate: PropTypes.string,
    enrollmentId: PropTypes.string,
    finalScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hidden: PropTypes.bool,
    mostRecentCompletedDate: PropTypes.string,
    pathwayCourseId: PropTypes.string,
    percentComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    startedDate: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

MyPlansCompliancePlanCard.defaultProps = {
  className: '',
  'data-testid': 'MyCompliancePlan',
};

const CompleteRibbon = styled(Ribbon)`
  height: 28px;
  width: 28px;
`;

export default MyPlansCompliancePlanCard;
