import Constants from 'constants/index';
import {
  isReportsPlansViewPath,
  isReportsTeamMembersViewPath,
  isTrainingModePath,
  isTrainingTeamMembersPath,
  isTrainingToCompletePath,
} from 'util/url';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import DocumentCompleteButton from './DocumentCompleteButton';
import ManageTeamMembersButtons from './ManageTeamMembersButtons';
import TrainingModeExitButton from './TrainingModeExitButton';
import PrintReportButton from './PrintReportButton';

const RightHeaderContent = ({ onWidth, otherWidth }) => {
  const [width, setWidth] = useState(0);
  const rightHeaderRef = useRef(null);

  const breakpoints = useBreakpoints();
  const isLgAndUp = useMediaQuery(breakpoints.up('lg'));

  const location = useLocation();
  const isCompliance = location?.search?.includes(
    Constants.PLAN_CATEGORIES.COMPLIANCE,
  );

  useEffect(() => {
    if (rightHeaderRef.current) {
      if (
        rightHeaderRef.current.clientWidth !== width &&
        rightHeaderRef.current.clientWidth !== otherWidth
      ) {
        setWidth(rightHeaderRef.current.clientWidth);
      }
    }
    if (rightHeaderRef.current.clientWidth === 0) {
      setWidth(0);
    }
  }, [otherWidth, rightHeaderRef, width, location]);

  useEffect(() => {
    if (isLgAndUp) {
      onWidth(width);
    } else {
      onWidth(0);
    }
  }, [onWidth, width, isLgAndUp]);

  const showManagePlansButtons =
    !!isTrainingTeamMembersPath(location) && !isCompliance;
  const showPrintReportButton =
    (!!isTrainingTeamMembersPath(location) && !!isCompliance) ||
    !!isReportsTeamMembersViewPath(location) ||
    !!isReportsPlansViewPath(location);
  const showExitTrainingModeButton = !!isTrainingModePath(location);
  const showCompleteButton =
    !!isTrainingToCompletePath(location) &&
    !isNil(location?.state?.step?.reference);

  return (
    <RightHeader $otherWidth={otherWidth} $width={width} ref={rightHeaderRef}>
      {showManagePlansButtons && <ManageTeamMembersButtons />}
      {showExitTrainingModeButton && <TrainingModeExitButton />}
      {showCompleteButton && <DocumentCompleteButton />}
      {showPrintReportButton && <PrintReportButton />}
    </RightHeader>
  );
};

RightHeaderContent.propTypes = {
  onWidth: PropTypes.func.isRequired,
  otherWidth: PropTypes.number.isRequired,
};

const RightHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: ${({ $width, $otherWidth }) =>
    $width >= $otherWidth ? 'auto' : `${$otherWidth}px`};
`;

export default RightHeaderContent;
