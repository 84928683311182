export default {
  Countries: {
    US: 'United States of America',
    CA: 'Canada',
    PR: 'Puerto Rico',
    iconLabel: {
      PR: 'PR',
      CA: 'Canada',
    },
  },
  Admin: {
    addCategory: 'Add Category',
    addIcon: 'Add Icon',
    addRecommended: 'Add Recommended Category',
    addSubcategory: 'Add Subcategory',
    addSection: 'Add Section',
    addResource: 'Add Resource',
    addSectionNamePlaceholder: 'Enter Name',
    addSectionTranslationPlaceholder: 'Enter Translation',
    browse: 'Browse',
    CA: 'Canada',
    cancelEditCategoryModalBody:
      'Are you sure you want to cancel? All unsaved changes will be lost.',
    categories: 'Categories',
    category: 'Category',
    categoryName: 'Category Name',
    categoryIsVisible: 'Visible',
    categoryHidden: 'Hidden',
    deleteCategoryModalBody:
      'Are you sure you want to delete the <strong>{{categoryName}}</strong> category?',
    deleteCategoryModalHeader: 'Delete Category',
    deleteCategoryToast: '{{categoryName}} has been successfully deleted.',
    deleteRecommendedCategoryModalBody:
      'Are you sure you want to delete the <strong>{{categoryName}}</strong> recommended category?',
    deleteRecommendedCategoryModalHeader: 'Delete Recommended Category',
    deleteResource:
      'Are you sure you want to delete the <strong>{{documentName}}</strong> resource?',
    deleteResourceHeader: 'Delete Resource',
    deleteSectionModalHeader: 'Delete Section',
    deleteSubcategoryModalBody:
      'Are you sure you want to delete the <strong>{{subcategoryName}}</strong> subcategory?',
    deleteSubcategoryModalHeader: 'Delete Subcategory',
    deleteSubcategoryToast:
      '{{subcategoryName}} has been successfully deleted.',
    deleteSectionModalBody:
      'Are you sure you want to delete the <strong>{{sectionName}}</strong> section?',
    editIcon: 'Edit Icon',
    makeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category from all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category immediately from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenHeader: 'Make Hidden',
    makeHiddenSubcategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subcategory from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenSubtitleBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subtitle from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category available to all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category immediately available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleHeader: 'Make Visible',
    makeVisibleSubcategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subcategory available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleSubtitleBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subtitle available to all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenDocumentBody:
      'Are you sure you want to hide the <strong>{{documentName}}</strong> resource from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleDocumentBody:
      'Are you sure you want to make the <strong>{{documentName}}</strong> resource available to all <strong>{{countryName}}</strong> Team Members?',
    managingCountry: 'Managing Country',
    PR: 'Puerto Rico',
    recommended: 'Recommended',
    recommendedCategory: 'Recommended Category',
    searchIcons: 'Search Icons',
    searchForIcons: 'Search for icons',
    sectionName: 'Section Name',
    selectIcon: 'Select Icon',
    spanishTranslation: 'Spanish Translation',
    subcategory: 'Subcategory',
    subcategories: 'Subcategories',
    updateCategoryToast: '{{categoryName}} has been successfully updated.',
    US: 'United States of America',
    warning: 'Warning',
  },
  Browse: {
    categories: 'Categories',
    recommended: 'Recommended',
    status: 'Status',
  },
  Button: {
    add: 'Add',
    addEllipsis: 'Add...',
    addOption: 'Add Option',
    addQuestion: 'Add Question',
    addTeamMembers: '+ Add Team Members',
    back: 'Back',
    backToTrainingMode: 'Back to Training Mode',
    cancel: 'Cancel',
    clearAll: 'Clear All',
    close: 'Close',
    complete: 'Complete',
    completed: 'Completed',
    complianceTraining: 'Compliance Training in Pathway 1.0',
    contactSupport: 'Contact CFA HELP',
    continue: 'Continue',
    copy: 'Copy',
    create: 'Create',
    createPlan: 'Create Plan',
    delete: 'Delete',
    deleteQuiz: 'Delete Quiz',
    done: 'Done',
    duplicate: 'Duplicate',
    edit: 'Edit',
    enablePlan: 'Enable Plan',
    exit: 'Exit',
    exitTrainingMode: 'Exit Training Mode',
    iUnderstand: 'I Understand',
    loadMore: 'Load More',
    markCompleted: 'Mark as Completed',
    next: 'Next',
    no: 'No',
    ok: 'OK',
    okay: 'Okay',
    preview: 'Preview',
    print: 'Print',
    printReport: 'Print Report',
    reloadThePage: 'Reload the page',
    returnToCfa: 'Return to @CFA',
    returnToHomepage: 'Return to Homepage',
    returnToPreviousPage: 'Return to Previous Page',
    retake: 'Retake Quiz',
    restartTraining: 'Restart Training',
    save: 'Save',
    saveQuiz: 'Save Quiz',
    select: 'Select',
    selectAll: 'Select All',
    stay: 'Stay',
    submit: 'Submit',
    trainingMode: 'Training Mode',
    translateAll: 'Translate All',
    unassign: 'Unassign',
    update: 'Update',
    updateDueDate: 'Update Due Date',
    wait: 'Wait',
    yes: 'Yes',
    yesCancelChanges: 'Yes, Cancel Changes',
    yesClose: 'Yes, Close',
    yesDelete: 'Yes, Delete',
    yesMakeHidden: 'Yes, Make Hidden',
    yesMakeVisible: 'Yes, Make Visible',
  },
  Generic: {
    admin: 'Admin',
    and: 'and',
    answer: 'Answer',
    around: 'Around',
    appName: 'Pathway',
    ascend: 'Ascend',
    assign: 'Assign Plan',
    at: 'at',
    cantUndoAction: 'You cannot undo this action.',
    chooseLocation: 'Choose Location(s)',
    clearSearch: 'Clear Search',
    comingSoon: 'Coming soon...',
    completed: 'Completed',
    completionDate: 'Completion Date',
    created: '(Created: {{createdDate}})',
    dateOfBirth: 'Date of Birth',
    edit: 'edit',
    enablePlan: 'Enable Plan',
    game: 'game',
    comingSoon2: 'Coming Soon',
    hour: 'hr',
    inProgress: 'In Progress',
    items: 'Items',
    itemsCompleted: 'Items Completed',
    of: 'of',
    link: 'Link',
    locations: 'Locations',
    markCompleted: 'Mark as Completed',
    editTranslations: 'Edit Translations',
    leadership: 'Leadership',
    marketTest: 'Test',
    mins: 'mins',
    menu: 'Menu',
    na: 'N/A',
    name: 'Name',
    notInCompliance: 'Not in Compliance',
    note: 'Note',
    notes: 'Notes',
    operations: 'Operations',
    plan: 'Plan Name',
    planCompleted: 'Plan Completed',
    plansCompleted: 'Plans Completed',
    popupArials: {
      labelledby: 'PopUpLabel',
      describedby: 'PopUpDesc',
    },
    printDocument: 'Print Document',
    reports: 'Reports',
    resource: 'Resource',
    restartTraining: 'Restart Training',
    percentCompleted: '{{percent}}% Completed',
    profile: 'Profile',
    procedure: 'Procedure',
    question: 'Question',
    quiz: 'Quiz',
    quizName: 'Quiz Name',
    search: 'Search',
    seeAll: 'See All',
    section: 'Section',
    settings: 'Settings',
    status: 'Status',
    task: 'Task',
    teamMember: 'Team Member',
    teamMembers: 'Team Members',
    teamMembersCompleted: 'Team Members Completed',
    this: 'This',
    totalTime: 'Total Time',
    totalTimeSpentOnPlan: 'Total Time Spent On Plan',
    trainingMode: 'Training Mode',
    unassign: 'Unassign',
    url: 'URL',
    video: 'Video',
  },
  GenericError: {
    cfaLogo: 'Chick-fil-A Logo',
    contactSupport: 'contact Customer service',
    errorHeader: 'Error',
    ifPersist: 'If the problem persists,',
    oops: 'Oops, something went wrong',
    or: 'or',
    reloadPage: 'Reload page',
    reloadThisPage: 'Reload this page',
    startOrder: 'Start a new order',
    tryReload: 'Please try reloading the page.',
    problemCompletingAction:
      'There was a problem completing this action. (Error code: 403)',
  },
  Input: {
    requiredMessage: 'is a required field.',
    Errors: {
      url: 'Please enter a valid URL.',
      task: 'Task name may not be empty.',
    },
  },
  InvalidPlanName: {
    errorHeader: 'Invalid Plan Name',
    paragraphText: 'Plans must have a name.',
  },
  InvalidQuizName: {
    errorHeader: 'Invalid Quiz Name',
    paragraphText: 'Quizzes must have a name.',
  },
  Language: {
    language: 'Language',
    english_language_code: 'en',
    spanish_language_code: 'es',
    english: 'English',
    spanish: 'Spanish',
  },
  LoadingResourceError: {
    errorHeader: 'Error',
    errorParagraph:
      'There was a problem loading the resource you are looking for.',
    unauthorizedHeader: 'Unauthorized',
    unauthorizedParagraph:
      'You are not authorized to view this resource. Please consult with your Operator or Leader for more information.',
  },
  Leadership: {
    ascendOnDemand: 'Ascend On-Demand',
    classLength: 'Length',
    hi: 'Hi',
    headerText: 'What would you like to learn today?',
    learnMore: 'Learn More',
    goThere: 'Go There',
    episodes: 'Skills',
    cohortHeader: 'Virtual Learning Groups',
    cohortText:
      'Group learning online for Restaurant leaders that explores both operational and leadership skills',
    continueWatching: 'Continue Watching',
    season: 'Collection',
    operator: 'Operator',
    pick: 'Pick',
  },
  NotFound: {
    button: 'Back to Home',
    error: '404',
    message: 'Page Not Found',
  },
  Reports: {
    backToTeamMembers: 'Back to Team Members',
    compliancePlanTooltip:
      'Further details are not available for compliance plans.',
    enablePlanTooltip: 'To enable this plan please go to the settings page.',
    filtering: {
      aToZ: 'A-Z',
      zToA: 'Z-A',
      searchForPlans: 'Search For Plans',
      searchPlans: 'Search Plans',
      searchTeamMembers: 'Search Team Members',
      startDate: 'Start Date',
    },
    noPlans:
      'There are no training plans to review. <br /> Plans that have been assigned to Team Members will be shown here.',
    tabCompliancePlans: 'Compliance Plans',
    tabPlans: 'Plans',
    tabTeamMembers: 'Team Members',
  },
  Settings: {
    complianceDisable:
      'Are you sure you want to disable the <strong>{{name}}</strong>? <br /> <br /> It will be disabled for all Team Members at each location under your Operator. <br /> <br /> Please check all local and state regulations to make sure that your Restaurant is in compliance.',
    complianceEnable:
      'Are you sure you want to enable the <strong>{{name}}</strong>? <br /> <br /> It will be enabled for all locations under your Operator.',
    compliancePlans: 'Compliance Plans',
    disabled: 'Disabled',
    disablePlan: 'Disable Plan',
    enabled: 'Enabled',
    enablePlan: 'Enable Plan',
    logout: 'Log out',
    status: 'Status: ',
    needAssistance: 'Need assistance?',
    setCountry: 'Set Country',
  },
  ResourceNotFoundError: {
    errorHeader: 'Error',
    errorParagraph:
      'This resource was not found. Please contact Chick-fil-A HELP online or call <a href="tel:+1-800-232-2677">1-800-232-2677</a>.',
  },
  Search: {
    filterBy: 'Filter:',
    historyRemove: 'Remove from search history',
    historySearchFor: 'Search for',
    noResults: 'No results found',
    numberOfResultsFor: 'results for',
    rateResult: 'Rate this search result',
    restaurantContentTypes: {
      lbm: 'LBM',
      stc: 'STC',
    },
    resultText: 'result',
    resultsText: 'results',
    searchForResources: 'Search for resources',
    showingResultsFooter:
      'Showing {{showingResults}} of {{numberOfResults}} {{resultsText}}',
    showingResultsHeader: 'Search Results',
    showingResultsSuggestion: 'Did you mean',
    submitFeedback: 'Thanks for your help!',
    teamMemberText: 'Team Member',
    teamMembersText: 'Team Members',
  },
  TrainingPlans: {
    accountability: {
      completedIn: 'Completed In',
      due: {
        completedDueDate: 'Completed: {{dueDate}}',
        dueDate: '{{dueDate}}',
        noDueDate: 'No Due Date',
        oneDay: 'Due in 1 day',
        oneWeek: 'Due in 1 week',
        overdue: 'Overdue',
        xDays: 'Due in {{daysLeft}} days',
        xWeeks: 'Due in {{weeksLeft}} weeks',
      },
      enterDueDate: 'Enter Due Date',
      setDueDate: 'Set Due Date',
      timeSpentOnPlan: 'Time Spent On Plan',
      itemsCompleted: 'Items Completed',
      itemsCompletedIn: 'Items Completed In',
      percentCompleted: '% Completed',
      planCompletedIn: 'Plan Completed In',
      toComplete: 'to complete',
      timeEstimationTooltip: 'Time should be entered in minutes',
      enterTime: 'Enter Time',
      estimatedTimeToComplete: 'Estimated Time To Complete Plan',
    },
    addPlanMenu: {
      chooseOperator: 'Choose Operator',
      customPlan: 'Custom Plan',
      templatePlan: 'Plan from Template Library',
      chooseLocations: 'Choose Location(s)',
      whichOperator: 'Which operator is the training plan for?',
    },
    addResource: {
      clearSearch: 'Add Resource clear search',
      placeholder: 'Search for anything',
      title: 'Resources',
    },
    addQuiz: {
      title: 'Quiz Library',
    },
    previewQuiz: {
      title: 'Preview: {{quizName}}',
      correctAnswer: 'Correct',
      noQuestions: 'No questions found in quiz.',
      noQuestionsHelpText:
        'You can still add this quiz to a plan and add questions later.',
    },
    addTaskFormPlaceholderText: {
      task: 'Type task here',
      notes: 'Enter Note',
      link: 'Enter URL',
    },
    assigned: 'Assigned',
    buildQuizzes: {
      addToTrainingPlan:
        'All quizzes must be part of an existing Training Plan. Would you like to add this quiz to a Training Plan now?',
      buildQuestionMultipleChoice: 'Enter options and choose the right answer.',
      buildQuestionTrueFalse: 'Choose the correct answer.',
      cancelBuilding: 'Cancel Building Quiz',
      cancelBuildText1:
        'Are you sure you want to cancel building the <strong>{{quizName}}</strong> Quiz?',
      cancelBuildText2: 'All data will be lost.',
      cancelEditText1:
        'Are you sure you want the cancel editing the <strong>{{quizName}}</strong> Quiz?',
      cancelEditText2: 'All unsaved data will be lost.',
      changeQuizName: 'Give your quiz a new name',
      chooseOperator: 'Choose Operator',
      correctAnswer: 'Correct answer',
      correctQuestionsThreshold:
        'How many questions must be answered correctly to pass the quiz?',
      createQuizHover: 'Create Quiz',
      deleteQuestion: 'Delete Question',
      deleteQuestionText:
        'Are you sure you want to permanently delete this question?',
      deleteQuiz:
        'Are you sure you want to delete the <strong>{{quizName}}</strong> Quiz? Deleting this quiz will also remove it from any training plan it is a part of.',
      enterAnswer: 'Enter Answer',
      enterQuestion: 'Enter Question',
      errorAnswer: 'Please enter answer option',
      errorSelectCorrectAnswer: 'Please select correct answer',
      errorSelectPassingGrade: 'Please Select',
      errorQuestion: 'Please enter answer option',
      giveQuizName: 'Give your quiz a name',
      manageQuiz: 'Manage Quiz',
      missingRequiredFields: 'Missing Required Fields',
      multipleChoice: 'Multiple Choice',
      noQuizzesPopup:
        'There are no quizzes. <br /> You can create quizzes in the Build Quizzes tab.',
      noQuizzesLoaded:
        'There are no quizzes. <br /> You can add quizzes with the + button below.',
      option: 'Option',
      pleaseNote: 'Please Note',
      question: 'Question',
      quizNamePlaceholder: 'Enter quiz name',
      saveBeforeRenamingQuiz:
        'Please ensure all required fields are populated and saved before renaming your quiz.',
      saveBeforeTranslatingQuiz:
        'Please ensure all required fields are populated and saved before translating your quiz.',
      trueOrFalse: 'True or False',
      validateRequiredFields:
        'Please ensure all required fields are populated before saving your quiz.',
      whichOperators: 'Which Operator is this quiz for?',
    },
    changePlanName: 'Give your plan a new name',
    completedIn: 'Completed In',
    completedPlans: 'Completed Plans',
    compliancePlans: 'Compliance Plans',
    compliancePlansTooltip:
      'In order to take a compliance plan, your Operator or Leader needs to enable the plan(s) in Settings.',
    createDuplicate: 'Create Duplicate',
    createPlan:
      'There are no training plans. <br /> You can add plans with the + button below.',
    createPlanPlaceholderText: 'My Training Plan 1',
    createPlanOnBuildPlanPage:
      'There are no training plans to manage. <br /> Leaders can create plans in the Build Plans tab.',
    currentPlans: 'Current Plans',
    deleteButton: 'Delete {{type}}',
    deletePlanText: 'Are you sure you want to delete the',
    deletePlanText2: 'plan?',
    deletePlanTextNew:
      'Are you sure you want to delete the <strong>{{planName}}</strong> Plan? You cannot undo this action.',
    deleteSectionItems: 'All items in this section will be deleted.',
    deleteSectionText:
      'Are you sure you want to permanently delete the entire <strong>{{activeSectionName}}</strong> section?<br/> All items in the section will be deleted.',
    deleteTaskItems: 'This task item will be deleted.',
    deleteTaskText:
      'Are you sure you want to permanently delete the <strong>{{activeDeleteTaskName}}</strong> {{type}}?',
    deleteTaskToast: '{{taskName}} has been successfully deleted.',
    duplicatePlanInputCopy: '- Copy',
    emptyPlan:
      'This plan does not have any resources, quizzes or tasks yet. <br /> You can add them with the + button below.',
    filtering: {
      filters: 'Filters',
      plan: 'Plan',
      plans: 'Plans',
      sortBy: 'Sort by:',
      searchBuildPlans: 'Search Build Plans',
      searchManagePlans: 'Search Manage Plans',
      searchMyPlans: 'Search My Plans',
      filterAndSort: 'Filter & Sort',
      show: 'Show',
      results: 'Results',
      aToZ: 'A-Z',
      zToA: 'Z-A',
      newest: 'Newest',
      oldest: 'Oldest',
      dueDate: 'Due Date',
      teamMembers: 'Team Members',
      includeCompleted: 'Include Completed:',
    },
    givePlanName: 'Give your Plan a name',
    managePlan: 'Manage Plan',
    manageThreeDotMenu: {
      delete: 'Delete Plan',
      deleteResource: 'Delete Resource',
      deleteQuiz: 'Delete Quiz',
      deleteSection: 'Delete section',
      deleteTask: 'Delete Task',
      duplicate: 'Duplicate Plan',
      editResource: 'Edit Resource',
      editSection: 'Edit section',
      editTask: 'Edit Task',
      locations: 'Choose Location',
      rename: 'Rename Plan',
      renameQuiz: 'Rename Quiz',
      renameSection: 'Rename Section',
      trainingMode: 'Training Mode',
    },
    manageTranslations: {
      answerTranslationPlaceholder: 'Translated Answer',
      notesTranslationPlaceholder: 'Translated Notes',
      nameTranslationPlaceholder: 'Translated Name',
      noExistingTranslation: 'No English translation',
      questionTranslationPlaceholder: 'Translated Question',
      sectionTranslationPlaceholder: 'Translated Section',
      translateTocurrentLanguage: 'Translate to Spanish', // note please tranaslate this to spanish as 'Translate to English'
      currentLanguageTranslation: 'Spanish Translation', // please translate to 'English Translation' in Spanish
      taskTranslationPlaceholder: 'Translated Task',
      translateAllTooltip:
        'This will translate all items and overwrite ALL existing text',
      urlTranslationPlaceholder: 'Enter Alternate URL',
      closeEditTranslations: 'Close Edit Translations',
      confirmCloseParagraph:
        'Are you sure you want to close <strong>Edit Translations</strong>? All updates will be lost.',
    },
    markCompleted1: 'Are you sure you want to mark the',
    markCompleted2: 'plan as completed?',
    noCompletedPlans: 'No plans are completed',
    noCurrentPlans: 'No plans are assigned to you',
    noTeamMembersResults:
      'There are no Team Members that match your current selections.',
    noPlansResults: 'There are no plans that match your current selections.',
    noResource:
      'This resource is no longer available.  Please contact your Leader.',
    noResourceBuild:
      'This resource is no longer available.  Please remove it from your training plan and contact HELP with any questions.',
    noResourceReference: 'There is no reference id assigned to this resource',
    noTeamMembersAssigned:
      'You do not have any Team Members assigned to this Training Plan yet',
    noTrainingPlans:
      'There are no training plans for you to work on at the moment.',
    planCategories: {
      default: 'Default',
      backOfHouse: 'Back of House',
      compliance: 'Compliance',
      frontOfHouse: 'Front of House',
      leadership: 'Leadership',
      hospitality: 'Hospitality',
      onboarding: 'Onboarding',
    },
    planNamePlaceholder: 'Enter a plan name',
    preview: 'Preview',
    quizzes: {
      calculatingQuiz: 'Calculating Quiz',
      congratulations: 'Congratulations',
      quizFail:
        'Sorry, you did not pass. Please retake the quiz. You must pass to complete your training.',
      quizPassed: 'Passed',
      quizResultsDelayed:
        'We’re sorry the quiz results are taking longer than usual to be calculated.',
      quizResultsIssue:
        'We’re sorry but this quiz has run into an issue, please try taking the quiz again.',
      quizSuccessPopup:
        'Congratulations, you passed with a score of {{score}}%',
      quizWaitingForResults:
        'Please wait while your results are being calculated...',
      youDidNotPass: 'You Did Not Pass',
    },
    restartPlan1: 'Are you sure you want this Team Member to restart the',
    restartPlan2:
      'plan? This will reset their progress back to 0 items complete.',
    requiredValue: 'is required',
    sectionName: 'Section Name',
    searchbarPlaceholder: 'Section title',
    showingXOfYPlans: 'Showing {{showing}} of {{total}} Plans',
    showingXOfYQuizzes: 'Showing {{showing}} of {{total}} Quizzes',
    showingXOfYTeamMembers: 'Showing {{showing}} of {{total}} Team Members',
    section: {
      addSectionItem: '+ Add ...',
      sectionItems: {
        addResource: 'Resources',
        addTask: 'Task',
        addQuiz: 'Quiz',
      },
      addSection: '+ Add Section',
    },
    selectCategory: 'Select Category',
    enterDueDate: 'Enter Due Date',
    setDueDate: 'Set Due Date',
    statusOptions: {
      attended: 'Attended',
      cancelled: 'Cancelled',
      completed: 'Completed',
      failed: 'Failed',
      inProgress: 'In Progress',
      locations: 'Locations',
      noShow: 'No Show',
      notCompleted: 'Not Completed',
      notStarted: 'Not Started',
      passed: 'Passed',
      pendingReview: 'Pending Review',
      status: 'Status',
    },
    tabAscend: 'Ascend',
    tabBuild: 'Build Plans',
    tabExplore: 'Explore',
    tabBuildQuizzes: 'Build Quizzes',
    tabMy: 'My Plans',
    tabManagePlans: 'Manage Plans',
    tabPlans: 'Plans',
    tasksCompleted: 'Tasks Completed',
    teamMembers: {
      count: 'Team Members - ',
      searchbarPlaceholder: 'Search Team Members',
      unassignConfirmation:
        'Are you sure you want to unassign {{name}}?  This plan will no longer be available.',
    },
    teamMembersCount: 'Team Members',
    templates: {
      templateModal: 'Template Library',
    },
    timeSpentOnPlanTooltip:
      'This is an approximation of the total time taken to complete this plan.',
    toastMessage: {
      assignedTeamMember:
        'You have successfully assigned 1 Team Member to this plan.',
      assignedTeamMembers:
        'You have successfully assigned {{count}} Team Members to this plan.',
      completed1: 'You have successfully completed the',
      completed2: 'Training Plan',
      created: 'has been successfully created.',
      deleted: 'has been successfully deleted.',
      duplicated: 'has been successfully duplicated.',
      enabled: '{{courseName}} has been successfully enabled.',
      renamed: 'has been successfully renamed.',
      sectionDeleted: 'section has been successfully deleted.',
      unassigned:
        '{{teamMember}} has been successfully unassigned from this plan.',
      updated: 'has been successfully updated',
      updateDueDate:
        'The Due Date for {{teamMember}} has been successfully updated.',
    },
    trainingMode: {
      completedPlanModal:
        'You have successfully completed the <strong>{{planName}}</strong> Training Plan.  Please hand the device back to your Leader.',
      enterTrainingModeModal:
        'You are now entering Training Mode.  If you choose to hand off your device to a Team Member, they can access any Pathway 2.0 page or Chick-Fil-A application from your credentials should they leave the Training Mode experience.',
      estimatedCompletionTime: 'Estimated Completion Time: {{estimatedTime}}',
      exitingModal:
        'You are about to exit training mode. Please hand the device back to your Leader, or return to training mode.',
      exitModal:
        'Your progress has been saved and you are now leaving training mode.  Please hand the device back to your Leader.',
      exitingTrainingMode: 'Exiting Training Mode',
      exitTrainingMode: 'Exit Training Mode',
      selectLanguage: 'Which language do you want this plan to display in?',
      toolTip: 'Please assign a Team Member to this plan to enable.',
    },
    trainingPlans: 'Training Plans',
    translationToastText: 'has been successfully translated to',
    storePlans: 'Store Plans',
    whichLocations: 'Which location(s) is this plan for?',
    whichLocationsPrintReport:
      'Which location(s) would you like to print this report for?',
    whichOperator: 'Which operator is the training plan for?',
  },
  UserNotAssignedToAppError: {
    errorHeader: 'Unauthorized',
    errorParagraph:
      'You cannot access Pathway 2.0 because you are not assigned this app in Okta. <strong>Please sign in with an authorized account.</strong>',
  },
  UserNotAssignedToRoleError: {
    errorHeader: 'Unauthorized',
    errorParagraph:
      'You cannot access this page because you do not have the correct permissions assigned in TMUA. Please consult with your Operator or Leader for more information.',
  },
};
