import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container as DragAndDropContainer } from 'react-smooth-dnd';
import { useEditCategoryMutation } from 'services/pathwayApi';
import styled from 'styled-components';
import { arrayMoveImmutable } from 'array-move';
import PropTypes from 'prop-types';
import {
  selectUsersHighestPermissionLevel,
  selectUserCountry,
} from 'store/user/selectors';
import { selectSubcategoryReducerState } from 'store/subcategory/selectors';
import {
  selectDocumentForDeletion,
  selectDocumentForVisibilityToggle,
  selectDocumentVisibilityFlagForConfirmationPopup,
  selectSelectedAdminSubcategoryIndex,
  selectSelectedAdminSubtitleIndex,
  selectTemporaryCategory,
} from 'store/admin/selectors';
import {
  Button,
  Icon,
  Tag,
  Typography,
  IconButton,
  AccordionItem,
  Accordion,
  TextField,
} from 'cfa-react-components';
import theme from 'styles/theme';
import {
  setSelectedCategoryId,
  setTemporaryCategory,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setShowAddAdminAddProcedureToSubtitle,
  setDocumentForVisibilityToggle,
  setDocumentVisibilityForConfirmationPopup,
  setDocumentForDeletion,
} from 'store/admin/slice';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuItemButton from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconCornerDownRight,
  IconEdit,
  IconEye,
  IconEyeOff,
  IconGripVertical,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import { setSubcategoryId } from '../../store/subcategory/slice';
import Subtitle from './Subtitle';
import SubtitleDocuments from './SubtitleDocuments';
import AddResourceToSubtitleButton from './AddResourceToSubtitleButton';

export const SubCategoryAccordion = ({
  addSectionFormIsValid,
  adminCategoryId,
  cancelEditSection,
  category,
  countryName,
  handleAddSection,
  handleAddSectionCancel,
  handleEditSection,
  handleSubcategoryVisibility,
  index,
  isAddProcedurePopUp,
  isEditingSubcategory,
  latestSubtitleIndex,
  mutatingSubcategorySection,
  onPendingSectionNameChange,
  onPendingSectionTranslationChange,
  onSaveSubtitle,
  onSaveSubtitles,
  onShowToast,
  openInNewTab,
  pendingSectionMutation,
  refetchCategories,
  RenderDocumentEndContent,
  sectionAddedToCategoryIndex,
  setIsEditingSubcategory,
  setSubcategory,
  showAdminFunctionality,
  showSubtitleEditFunctionality,
  subcategory,
  subcategoryClassName,
  subcategoryIsVisible,
  triggerCancelEditSectionState,
  triggerEnableCancelButton,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name: subcategoryName, subtitles } = subcategory;
  const selectedSubcategoryId = useSelector(selectSubcategoryReducerState);
  const selectedTemporaryCategory = useSelector(selectTemporaryCategory);
  const selectedCountry = useSelector(selectUserCountry);
  const selectedDocumentForVisibilityToggle = useSelector(
    selectDocumentForVisibilityToggle,
  );
  const selectedSubcategoryIndex = useSelector(
    selectSelectedAdminSubcategoryIndex,
  );
  const selectedSubtitleIndex = useSelector(selectSelectedAdminSubtitleIndex);
  const documentBeingMutatedIsVisible = useSelector(
    selectDocumentVisibilityFlagForConfirmationPopup,
  );
  const selectedDocumentForDeletion = useSelector(selectDocumentForDeletion);
  const [editCategory] = useEditCategoryMutation();
  const [mutableSubtitles, setMutableSubtitles] = useState(subtitles);
  const [showConfirmationVisibilityPopUp, setShowConfirmationVisibilityPopUp] =
    useState(false);
  const [
    showConfirmationForDocumentVisibilityPopup,
    setShowConfirmationForDocumentVisibilityPopup,
  ] = useState(false);
  const [
    showConfirmationForDocumentDeletionPopup,
    setShowConfirmationForDocumentDeletionPopup,
  ] = useState(false);
  const [showDeleteSubcategoryPopUp, setShowDeleteSubcategoryPopUp] =
    useState(false);
  const [showDeleteSectionPopUp, setShowDeleteSectionPopUp] = useState(false);
  const [editSection, setEditSection] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [subtitle, setSubtitle] = useState({
    index: '',
    name: { en: '', es: '' },
    documents: [],
    visibleDocuments: [],
  });

  let userIsAdmin = false;
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  if (showAdminFunctionality) {
    userIsAdmin = Constants.USER_PERMISSIONS.ADMIN === userPermissionLevel;
  }

  useEffect(() => {
    if (subtitles) {
      setMutableSubtitles(subtitles);
    }
  }, [subtitles]);

  useEffect(() => {
    if (cancelEditSection) {
      setEditSection(false);
      triggerCancelEditSectionState();
    }
  }, [cancelEditSection, triggerCancelEditSectionState]);

  // We need this to avoid an edge case bug (see P20-2425)
  useEffect(() => {
    if (subtitle?.documents?.length > 0) {
      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
  }, [subtitle.documents]);

  const onSubcategoryEdit = () => {
    setSubcategory(true);
    setIsEditingSubcategory(true);
    triggerEnableCancelButton();
  };

  const onNameChange = e => {
    const name = e.target.value;
    if (name.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...selectedTemporaryCategory.subcategories[index],
              name: {
                en: name,
                es: selectedTemporaryCategory.subcategories[index].name.es,
              },
            },
          }),
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...selectedTemporaryCategory.subcategories[index],
              name: {
                en: '',
                es: selectedTemporaryCategory.subcategories[index].name.es,
              },
            },
          }),
        }),
      );
    }
  };

  const onTranslationChange = e => {
    const translation = e.target.value;
    if (translation.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...selectedTemporaryCategory.subcategories[index],
              name: {
                en: selectedTemporaryCategory.subcategories[index].name.en,
                es: translation,
              },
            },
          }),
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...selectedTemporaryCategory.subcategories[index],
              name: {
                en: selectedTemporaryCategory.subcategories[index].name.en,
                es: '',
              },
            },
          }),
        }),
      );
    }
  };

  const updateArrayIndex = (array, removedIndex, addedIndex) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(item => {
      return { ...item };
    });
  };

  const onDrop = dropResult => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex } = dropResult;

    const updatedSubtitles = updateArrayIndex(
      mutableSubtitles,
      removedIndex,
      addedIndex,
    );
    setMutableSubtitles(updatedSubtitles);
    onSaveSubtitles(updatedSubtitles, index);
  };

  const handleAdminAddResourceToNewSubtitle = (
    subcategoryIndex,
    subtitleIndex,
  ) => {
    dispatch(setSelectedCategoryId(adminCategoryId));
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setShowAddAdminAddProcedureToSubtitle(true));
  };

  const handleDocumentVisibilityToggleForSubtitle = (
    subcategoryIndex,
    subtitleIndex,
    document,
    documentIsMarkedAsVisible,
  ) => {
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForVisibilityToggle(document));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    setShowConfirmationForDocumentVisibilityPopup(true);
  };

  const handleAddResourceToExistingSubtitle = (
    subcategoryIndex,
    subtitleIndex,
  ) => {
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setShowAddAdminAddProcedureToSubtitle(true));
    setEditSection(false);
  };

  const handleDocumentDeleteFromSubcategorySubtitle = (
    subcategoryIndex,
    subtitleIndex,
    document,
    documentIsMarkedAsVisible,
    documentIndex,
  ) => {
    dispatch(setSelectedCategoryId(adminCategoryId));
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForDeletion({ ...document, index: documentIndex }));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    setShowConfirmationForDocumentDeletionPopup(true);
  };

  const resetSubtitleMutationState = () => {
    dispatch(setSelectedSubcategoryIndex(null));
    dispatch(setSelectedSubtitleIndex(null));
    dispatch(setDocumentVisibilityForConfirmationPopup(null));
  };

  const onCancelToggleSubtitleDocumentVisibility = () => {
    resetSubtitleMutationState();
    dispatch(setDocumentForVisibilityToggle(null));
    setShowConfirmationForDocumentVisibilityPopup(false);
  };

  const onCancelToggleSubtitleDocumentDeletion = () => {
    resetSubtitleMutationState();
    dispatch(setDocumentForDeletion(null));
    setShowConfirmationForDocumentDeletionPopup(false);
  };

  const deleteDocumentFromSubtitle = isInEditSectionMode => {
    const { id: documentId, name: documentName } = selectedDocumentForDeletion;
    if (isInEditSectionMode) {
      if (documentBeingMutatedIsVisible) {
        setSubtitle({
          ...subtitle,
          visibleDocuments: [
            ...subtitle.visibleDocuments.filter(id => id !== documentId),
          ],
        });
      }
      setSubtitle({
        ...subtitle,
        documents: [
          ...subtitle.documents.filter(({ id }) => id !== documentId),
        ],
      });
    }

    const updatedTemporaryCategory = {
      ...category,
      subcategories: [
        ...selectedTemporaryCategory.subcategories.map(
          (subcat, mappedSubcatIndex) => {
            return mappedSubcatIndex === selectedSubcategoryIndex
              ? {
                  ...subcat,
                  subtitles: [
                    ...subcat.subtitles.map(
                      (mappedSubtitle, mappedSubtitleIndex) => {
                        return mappedSubtitleIndex === selectedSubtitleIndex
                          ? {
                              ...mappedSubtitle,
                              documents: mappedSubtitle.documents.filter(
                                (doc, docIndex) =>
                                  docIndex !==
                                  selectedDocumentForDeletion.index,
                              ),
                            }
                          : mappedSubtitle;
                      },
                    ),
                  ],
                }
              : subcat;
          },
        ),
      ],
    };

    if (
      selectedDocumentForDeletion?.id ===
      category?.subcategories?.[selectedSubcategoryIndex]?.subtitles?.[
        selectedSubtitleIndex
      ].documents?.[selectedDocumentForDeletion?.index]?.id
    ) {
      const updatedCategory = {
        ...category,
        subcategories: [
          ...category.subcategories.map((subcat, mappedSubcatIndex) => {
            return mappedSubcatIndex === selectedSubcategoryIndex
              ? {
                  ...subcat,
                  subtitles: [
                    ...subcat.subtitles.map(
                      (mappedSubtitle, mappedSubtitleIndex) => {
                        return mappedSubtitleIndex === selectedSubtitleIndex
                          ? {
                              ...mappedSubtitle,
                              documents: mappedSubtitle.documents.filter(
                                (doc, docIndex) =>
                                  docIndex !==
                                  selectedDocumentForDeletion.index,
                              ),
                            }
                          : mappedSubtitle;
                      },
                    ),
                  ],
                }
              : subcat;
          }),
        ],
      };

      editCategory(updatedCategory).then(() => {
        dispatch(setTemporaryCategory(updatedTemporaryCategory));
        onShowToast(getNameFromLanguage(documentName));
        dispatch(setDocumentForDeletion(null));
        setShowConfirmationForDocumentDeletionPopup(false);
      });
    } else {
      dispatch(setTemporaryCategory(updatedTemporaryCategory));
      setShowConfirmationForDocumentDeletionPopup(false);
      dispatch(setDocumentForDeletion(null));
    }
  };

  const onToggleSubtitleDocumentVisibility = isInEditSectionMode => {
    const { id: documentId } = selectedDocumentForVisibilityToggle;
    const mutableCategory = cloneDeep(selectedTemporaryCategory);
    const documentIsCurrentlyVisible =
      mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
        selectedSubtitleIndex
      ].visibleDocuments.indexOf(documentId) > -1;
    if (isInEditSectionMode) {
      if (documentIsCurrentlyVisible) {
        setSubtitle({
          ...subtitle,
          visibleDocuments: [
            ...subtitle.visibleDocuments.filter(id => id !== documentId),
          ],
        });
      } else {
        setSubtitle({
          ...subtitle,
          visibleDocuments: [...subtitle.visibleDocuments, documentId],
        });
      }
    }
    if (documentIsCurrentlyVisible) {
      mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
        selectedSubtitleIndex
      ].visibleDocuments = mutableCategory.subcategories[
        selectedSubcategoryIndex
      ].subtitles[selectedSubtitleIndex].visibleDocuments.filter(
        id => id !== documentId,
      );
    } else {
      mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
        selectedSubtitleIndex
      ].visibleDocuments.push(documentId);
    }
    resetSubtitleMutationState();
    dispatch(setTemporaryCategory(mutableCategory));
    dispatch(setDocumentForVisibilityToggle(null));
    setShowConfirmationForDocumentVisibilityPopup(false);
  };

  const onToggleSubcategoryVisibility = () => {
    handleSubcategoryVisibility(getNameFromLanguage(subcategory.name));
    setShowConfirmationVisibilityPopUp(false);
  };

  const onDeleteSubcategory = () => {
    setShowDeleteSubcategoryPopUp(false);
    const futureSubcategories = [...category.subcategories];
    const removedSubcategory = futureSubcategories.splice(index, 1);
    dispatch(
      setTemporaryCategory({
        ...category,
        subcategories: futureSubcategories,
      }),
    );
    editCategory({
      ...category,
      subcategories: futureSubcategories,
    }).then(() => {
      refetchCategories();
      onShowToast(getNameFromLanguage(removedSubcategory?.[0]?.name));
      dispatch(
        setSubcategoryId({
          id: -1,
        }),
      );
    });
  };

  const onHandleDeleteSection = (idx, name) => {
    setSubtitle({
      index: idx,
      name,
    });
    setShowDeleteSectionPopUp(true);
  };

  const onHandleEditSection = (idx, name, documents, visibleDocuments) => {
    setEditSection(true);
    setSubtitle({
      index: idx,
      name,
      documents,
      visibleDocuments,
    });
  };

  const onDeleteSection = () => {
    setShowDeleteSectionPopUp(false);
    const futureSubcategories = [...category.subcategories];
    const futureSubtitles = [...[...category.subcategories][index].subtitles];
    const removedSubtitle = futureSubtitles.splice(subtitle.index, 1);

    dispatch(
      setTemporaryCategory({
        ...category,
        subcategories: futureSubcategories.map((it, ind) =>
          ind === index
            ? {
                ...it,
                subtitles: it.subtitles.filter(
                  // eslint-disable-next-line
                  (it, idx) => idx !== subtitle.index,
                ),
              }
            : it,
        ),
      }),
    );
    editCategory({
      ...category,
      subcategories: futureSubcategories.map((it, ind) =>
        ind === index
          ? {
              ...it,
              subtitles: it.subtitles.filter(
                // eslint-disable-next-line
                (it, idx) => idx !== subtitle.index,
              ),
            }
          : it,
      ),
    }).then(refetchCategories);

    // We guard against adding a subtitle and deleting it before it's saved
    getNameFromLanguage(removedSubtitle?.[0]?.name) &&
      onShowToast(getNameFromLanguage(removedSubtitle?.[0]?.name));
  };

  const handleDocumentConfirmationsOnClose = () => {
    setShowConfirmationForDocumentDeletionPopup(false);
    setShowConfirmationForDocumentVisibilityPopup(false);
    resetSubtitleMutationState();
    dispatch(setDocumentForDeletion(null));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentBeingMutatedIsVisible),
    );
  };

  const onAddSection = () => {
    setSubtitle({
      ...subtitle,
      documents: [],
      visibleDocuments: [],
    });
    handleAddSection();
  };

  return (
    <Accordion
      data-testid={`Subcategory${index}`}
      defaultActiveItemKey={null}
      elevation={1}
      key={index.toString()}
      onClick={() =>
        (isEditingSubcategory &&
          selectedSubcategoryId.id !== index &&
          !isAddProcedurePopUp) ||
        (!isEditingSubcategory && !isAddProcedurePopUp)
          ? setSubcategory()
          : null
      }
      variant="surface"
    >
      <AccordionItem
        data-testid={`SubTitle${index}`}
        header={
          <AccordionHeader>
            {!!userIsAdmin && !!showAdminFunctionality && (
              <StyledSixDotIcon className={subcategoryClassName} />
            )}
            {isEditingSubcategory && selectedSubcategoryId.id === index ? (
              <>
                <SubcategoryTitleRow onClick={e => e.stopPropagation()}>
                  <StyledFormControlWrapper>
                    <TextField
                      data-testid={'EditSubcategoryNameInput'}
                      fullWidth
                      label={t('Admin.subcategory')}
                      onChange={onNameChange}
                      placeholder={'Enter Name'}
                      required={true}
                      value={
                        selectedTemporaryCategory.subcategories[index].name.en
                      }
                    />
                  </StyledFormControlWrapper>
                  <StyledTranslationRow>
                    <IconCornerDownRight size={35} />
                    <TextField
                      data-testid={'EditSubcategoryTranslationInput'}
                      fullWidth
                      label={t('Admin.spanishTranslation')}
                      onChange={onTranslationChange}
                      placeholder={'Enter Translation'}
                      required={
                        countryName !==
                        Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
                      }
                      value={
                        selectedTemporaryCategory.subcategories[index].name.es
                      }
                    />
                  </StyledTranslationRow>
                </SubcategoryTitleRow>
                {!!userIsAdmin && !!showAdminFunctionality && (
                  <EditAdminFeaturesWrapper>
                    {subcategoryIsVisible ? (
                      <StyledShowTag
                        label={t('Admin.categoryIsVisible')}
                        leadingElement={<IconEye width={10} />}
                        onClick={() => setShowConfirmationVisibilityPopUp(true)}
                        variant="filled"
                      />
                    ) : !subcategoryIsVisible ? (
                      <StyledHideTagWrapper
                        onClick={() => setShowConfirmationVisibilityPopUp(true)}
                      >
                        <StyledHideTag
                          disabled
                          label={t('Admin.categoryHidden')}
                          leadingElement={<IconEyeOff width={10} />}
                          variant="filled"
                        />
                      </StyledHideTagWrapper>
                    ) : null}
                    <StyledDeleteIconButton
                      aria-label="Delete Category"
                      color="default"
                      data-testid="EditCategoryTrashIcon"
                      href=""
                      onClick={() => setShowDeleteSubcategoryPopUp(true)}
                      size="md"
                    >
                      <IconTrash />
                    </StyledDeleteIconButton>
                  </EditAdminFeaturesWrapper>
                )}
              </>
            ) : (
              <AccordionHeaderRow>
                <AccordionTitle variant="body1">
                  {getNameFromLanguage(subcategoryName)}
                </AccordionTitle>
                {!!userIsAdmin && !!showAdminFunctionality && (
                  <AdminFeaturesWrapper>
                    {subcategoryIsVisible ? (
                      <StyledShowTag
                        data-testid="EditSubcategoryToggleShow"
                        label={t('Admin.categoryIsVisible')}
                        leadingElement={<IconEye width={10} />}
                        onClick={e => {
                          // We do this to prevent the accordion from opening/closing
                          e.stopPropagation();
                          setShowConfirmationVisibilityPopUp(true);
                        }}
                        variant="filled"
                      />
                    ) : !subcategoryIsVisible ? (
                      <StyledHideTagWrapper
                        onClick={e => {
                          e.stopPropagation();
                          setShowConfirmationVisibilityPopUp(true);
                        }}
                      >
                        <StyledHideTag
                          data-testid="EditSubcategoryToggleHide"
                          disabled
                          label={t('Admin.categoryHidden')}
                          leadingElement={<IconEyeOff width={10} />}
                          variant="filled"
                        />
                      </StyledHideTagWrapper>
                    ) : null}
                    <PopoverMenuButton>
                      <PopoverMenuItemButton
                        icon={<IconEdit />}
                        onClick={onSubcategoryEdit}
                        text={t('Button.edit')}
                      />
                      <PopoverMenuItemButton
                        icon={<IconTrash />}
                        isDestructive={true}
                        onClick={() => setShowDeleteSubcategoryPopUp(true)}
                        text={t('Button.delete')}
                      />
                    </PopoverMenuButton>
                  </AdminFeaturesWrapper>
                )}
              </AccordionHeaderRow>
            )}
          </AccordionHeader>
        }
        onClick={e => e.stopPropagation()}
      >
        <StyledContainerWrapper>
          <DragAndDropContainer
            className="StyledDragAndDropContainer"
            dragHandleSelector=".subtitle-drag-handle"
            onDrop={e => onDrop(e)}
          >
            {!editSection &&
              mutableSubtitles?.map((mutableSubtitle, subtitleIndex) => {
                return (
                  <Subtitle
                    countryName={selectedCountry.id}
                    handleDocumentDelete={(
                      document,
                      documentIsMarkedAsVisible,
                      documentIndex,
                    ) => {
                      handleDocumentDeleteFromSubcategorySubtitle(
                        index,
                        subtitleIndex,
                        document,
                        documentIsMarkedAsVisible,
                        documentIndex,
                      );
                    }}
                    handleDocumentVisibilityToggleForSubtitle={(
                      document,
                      documentIsMarkedAsVisible,
                    ) => {
                      handleDocumentVisibilityToggleForSubtitle(
                        index,
                        subtitleIndex,
                        document,
                        documentIsMarkedAsVisible,
                      );
                    }}
                    isNewlyAdded={
                      latestSubtitleIndex === subtitleIndex &&
                      sectionAddedToCategoryIndex === index
                    }
                    key={`subtitle-${subtitleIndex}`}
                    onAdminAddResourceToSubtitle={() =>
                      handleAdminAddResourceToNewSubtitle(index, subtitleIndex)
                    }
                    onDeleteSubtitle={(idx, name) =>
                      onHandleDeleteSection(idx, name)
                    }
                    onEditSubtitle={(idx, name, documents, visibleDocuments) =>
                      onHandleEditSection(
                        idx,
                        name,
                        documents,
                        visibleDocuments,
                      )
                    }
                    onSaveSubtitles={onSaveSubtitles}
                    openInNewTab={openInNewTab}
                    RenderDocumentEndContent={RenderDocumentEndContent}
                    showAdminFunctionality={showAdminFunctionality}
                    showSubtitleEditFunctionality={
                      showSubtitleEditFunctionality
                    }
                    subcategoryIndex={index}
                    subtitle={mutableSubtitle}
                    subtitleIndex={subtitleIndex}
                    subtitles={mutableSubtitles}
                    triggerEnableCancelButton={triggerEnableCancelButton}
                    userIsAdmin={userIsAdmin}
                  />
                );
              })}
          </DragAndDropContainer>
        </StyledContainerWrapper>
        {!!userIsAdmin && !!showAdminFunctionality && (
          <>
            {!mutatingSubcategorySection && !editSection ? (
              <Button color="secondary" onClick={onAddSection} variant="text">
                <StyledPlusIcon icon={IconPlus} size="sm" />
                <Typography
                  color="secondary"
                  data-testid="AddSubcategorySection"
                  variant="body1"
                >
                  {t('Admin.addSection')}
                </Typography>
              </Button>
            ) : (
              <>
                <StyledAddSectionRow>
                  <TextField
                    data-testid={'EditSectionNameInput'}
                    fullWidth
                    label={t('Admin.sectionName')}
                    onChange={
                      editSection
                        ? event => {
                            handleEditSection({
                              index,
                              language: Constants.LANGUAGE_OPTIONS.ENGLISH,
                              name: event.target.value,
                              subtitleIndex: subtitle.index,
                            });
                            setSubtitle(prev => {
                              return {
                                ...prev,
                                name: {
                                  en: event.target.value,
                                  es: prev.name.es,
                                },
                              };
                            });
                          }
                        : onPendingSectionNameChange
                    }
                    placeholder={t('Admin.addSectionNamePlaceholder')}
                    required={true}
                    value={
                      editSection
                        ? subtitle.name.en
                        : pendingSectionMutation.name.en
                    }
                  />
                </StyledAddSectionRow>
                <StyledAddSectionRow>
                  <IconCornerDownRight size={35} />
                  <TextField
                    data-testid={'EditSectionNameTranslationInput'}
                    fullWidth
                    label={t('Admin.spanishTranslation')}
                    onChange={
                      editSection
                        ? event => {
                            handleEditSection({
                              index,
                              language: Constants.LANGUAGE_OPTIONS.SPANISH,
                              name: event.target.value,
                              subtitleIndex: subtitle.index,
                            });
                            setSubtitle(prev => {
                              return {
                                ...prev,
                                name: {
                                  en: prev.name.en,
                                  es: event.target.value,
                                },
                              };
                            });
                          }
                        : onPendingSectionTranslationChange
                    }
                    placeholder={t('Admin.addSectionTranslationPlaceholder')}
                    required={
                      countryName !==
                      Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
                    }
                    value={
                      editSection
                        ? subtitle.name.es
                        : pendingSectionMutation.name.es
                    }
                  />
                </StyledAddSectionRow>
                <>
                  {showDocuments && (
                    <SubtitleDocuments
                      contentToRenderAtDocumentEnd={RenderDocumentEndContent}
                      disableSixDotMenuHandles={true}
                      handleDocumentDelete={(
                        document,
                        documentIsMarkedAsVisible,
                        documentIndex,
                      ) =>
                        handleDocumentDeleteFromSubcategorySubtitle(
                          index,
                          subtitle.index,
                          document,
                          documentIsMarkedAsVisible,
                          documentIndex,
                        )
                      }
                      handleDocumentVisibilityToggleForSubtitle={(
                        document,
                        documentIsMarkedAsVisible,
                      ) =>
                        handleDocumentVisibilityToggleForSubtitle(
                          index,
                          subtitle.index,
                          document,
                          documentIsMarkedAsVisible,
                        )
                      }
                      openDocumentInNewTab={openInNewTab}
                      showAdminFunctionality={true}
                      subtitle={subtitle}
                      userIsAdmin={userIsAdmin}
                    />
                  )}
                  {subtitle.index !== '' && (
                    <AddResourceToSubtitleButton
                      onAdminAddResourceToSubtitle={() =>
                        handleAddResourceToExistingSubtitle(
                          index,
                          subtitle.index,
                        )
                      }
                    />
                  )}
                </>
                {!editSection && (
                  <StyledAddSectionRow>
                    <AddSectionActionsWrapper>
                      <Button
                        color="secondary"
                        onClick={handleAddSectionCancel}
                        variant="outlined"
                      >
                        {t('Button.cancel')}
                      </Button>
                      <Button
                        color="secondary"
                        data-testid="AddSectionSaveButton"
                        disabled={!addSectionFormIsValid}
                        onClick={() =>
                          onSaveSubtitle(pendingSectionMutation, index)
                        }
                        variant="filled"
                      >
                        {t('Button.add')}
                      </Button>
                    </AddSectionActionsWrapper>
                  </StyledAddSectionRow>
                )}
              </>
            )}
          </>
        )}
      </AccordionItem>
      <ConfirmationModal
        bodyText={
          subcategoryIsVisible
            ? t('Admin.makeHiddenSubcategoryBody', {
                categoryName: getNameFromLanguage(subcategory?.name),
                countryName,
              })
            : t('Admin.makeVisibleSubcategoryBody', {
                categoryName: getNameFromLanguage(subcategory?.name),
                countryName,
              })
        }
        headerText={
          subcategoryIsVisible
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showConfirmationVisibilityPopUp}
        onClose={e => {
          e.stopPropagation();
          setShowConfirmationVisibilityPopUp(false);
        }}
        primaryButtonColor={subcategoryIsVisible ? 'primary' : 'secondary'}
        primaryButtonHandler={e => {
          e.stopPropagation();
          onToggleSubcategoryVisibility();
        }}
        primaryButtonText={
          subcategoryIsVisible
            ? t('Button.yesMakeHidden')
            : t('Button.yesMakeVisible')
        }
        secondaryButtonHandler={e => {
          e.stopPropagation();
          setShowConfirmationVisibilityPopUp(prev => !prev);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Admin.deleteSubcategoryModalBody', {
          subcategoryName: getNameFromLanguage(subcategory?.name),
        })}
        headerText={t('Admin.deleteSubcategoryModalHeader')}
        isOpen={showDeleteSubcategoryPopUp}
        onClose={() => setShowDeleteSubcategoryPopUp(false)}
        primaryButtonHandler={onDeleteSubcategory}
        primaryButtonText={t('Button.yesDelete')}
        secondaryButtonHandler={prev => setShowDeleteSubcategoryPopUp(!prev)}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Admin.deleteSectionModalBody', {
          sectionName: getNameFromLanguage(subtitle?.name),
        })}
        headerText={t('Admin.deleteSectionModalHeader')}
        isOpen={showDeleteSectionPopUp}
        onClose={e => {
          e.stopPropagation();
          setShowDeleteSectionPopUp(false);
        }}
        primaryButtonHandler={e => {
          e.stopPropagation();
          onDeleteSection();
        }}
        primaryButtonText={t('Button.yesDelete')}
        secondaryButtonHandler={e => {
          e.stopPropagation();
          setShowDeleteSectionPopUp(prev => !prev);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={
          documentBeingMutatedIsVisible
            ? t('Admin.makeHiddenDocumentBody', {
                countryName: selectedCountry.LABEL_KEY ?? '',
                documentName:
                  getNameFromLanguage(
                    selectedDocumentForVisibilityToggle?.name,
                  ) ?? '',
              })
            : t('Admin.makeVisibleDocumentBody', {
                countryName: selectedCountry.LABEL_KEY ?? '',
                documentName:
                  getNameFromLanguage(
                    selectedDocumentForVisibilityToggle?.name,
                  ) ?? '',
              })
        }
        headerText={
          documentBeingMutatedIsVisible
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showConfirmationForDocumentVisibilityPopup}
        onClose={e => {
          e.stopPropagation();
          handleDocumentConfirmationsOnClose();
        }}
        primaryButtonColor={
          documentBeingMutatedIsVisible ? 'primary' : 'secondary'
        }
        primaryButtonHandler={e => {
          e.stopPropagation();
          onToggleSubtitleDocumentVisibility(editSection);
        }}
        primaryButtonText={
          documentBeingMutatedIsVisible
            ? t('Button.yesMakeHidden')
            : t('Button.yesMakeVisible')
        }
        secondaryButtonHandler={e => {
          e.stopPropagation();
          onCancelToggleSubtitleDocumentVisibility();
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Admin.deleteResource', {
          documentName:
            getNameFromLanguage(selectedDocumentForDeletion?.name) ?? '',
        })}
        headerText={t('Admin.deleteResourceHeader')}
        isOpen={showConfirmationForDocumentDeletionPopup}
        onClose={e => {
          e.stopPropagation();
          handleDocumentConfirmationsOnClose();
        }}
        primaryButtonColor={'primary'}
        primaryButtonHandler={e => {
          e.stopPropagation();
          deleteDocumentFromSubtitle(editSection);
        }}
        primaryButtonText={t('Button.yesDelete')}
        secondaryButtonHandler={e => {
          e.stopPropagation();
          onCancelToggleSubtitleDocumentDeletion();
        }}
        secondaryButtonText={t('Button.cancel')}
      />
    </Accordion>
  );
};

SubCategoryAccordion.propTypes = {
  addSectionFormIsValid: PropTypes.bool,
  adminCategoryId: PropTypes.string,
  cancelEditSection: PropTypes.bool,
  category: PropTypes.object,
  countryName: PropTypes.string,
  handleAddSection: PropTypes.func,
  handleAddSectionCancel: PropTypes.func,
  handleEditSection: PropTypes.func,
  handleSubcategoryVisibility: PropTypes.func,
  index: PropTypes.number.isRequired,
  isAddProcedurePopUp: PropTypes.bool,
  isEditingSubcategory: PropTypes.bool,
  latestSubtitleIndex: PropTypes.number,
  mutatingSubcategorySection: PropTypes.bool,
  onPendingSectionNameChange: PropTypes.func,
  onPendingSectionTranslationChange: PropTypes.func,
  onSaveSubtitle: PropTypes.func,
  onSaveSubtitles: PropTypes.func,
  onShowToast: PropTypes.func,
  openInNewTab: PropTypes.bool,
  pendingSectionMutation: PropTypes.object,
  refetchCategories: PropTypes.func,
  RenderDocumentEndContent: PropTypes.func,
  sectionAddedToCategoryIndex: PropTypes.number,
  setIsEditingSubcategory: PropTypes.func,
  setSubcategory: PropTypes.func,
  showAdminFunctionality: PropTypes.bool,
  showSubtitleEditFunctionality: PropTypes.bool,
  subcategory: PropTypes.object.isRequired,
  subcategoryClassName: PropTypes.string,
  subcategoryIsVisible: PropTypes.bool,
  triggerCancelEditSectionState: PropTypes.func,
  triggerEnableCancelButton: PropTypes.func,
};

SubCategoryAccordion.defaultProps = {
  addSectionFormIsValid: false,
  adminCategoryId: null,
  cancelEditSection: false,
  category: {},
  countryName: '',
  handleAddSection: () => {},
  handleAddSectionCancel: () => {},
  handleEditSection: () => {},
  handleSubcategoryVisibility: () => {},
  isAddProcedurePopUp: false,
  isEditingSubcategory: false,
  latestSubtitleIndex: null,
  mutatingSubcategorySection: false,
  onPendingSectionNameChange: () => {},
  onPendingSectionTranslationChange: () => {},
  onSaveSubtitle: () => {},
  onSaveSubtitles: () => {},
  onShowToast: null,
  openInNewTab: false,
  pendingSectionMutation: {
    name: { en: '', es: '' },
  },
  refetchCategories: null,
  RenderDocumentEndContent: null,
  sectionAddedToCategoryIndex: null,
  setSubcategory: () => {},
  setIsEditingSubcategory: () => {},
  showAdminFunctionality: false,
  showSubtitleEditFunctionality: false,
  subcategoryClassName: '',
  subcategoryIsVisible: true,
  triggerCancelEditSectionState: () => {},
  triggerEnableCancelButton: () => {},
};

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const AccordionHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const AccordionTitle = styled(Typography)`
  margin-left: 16px;
`;

const StyledTranslationRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledDeleteIconButton = styled(IconButton)`
  margin-left: auto;
`;

const StyledAddSectionRow = styled.div`
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AddSectionActionsWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
`;

const StyledPlusIcon = styled(Icon)`
  margin-right: 8px;
  color: ${() => theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

const SubcategoryTitleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
  padding: 0 16px;
`;

const StyledFormControlWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
`;

const StyledSixDotIcon = styled(IconGripVertical)`
  width: 25px;
  height: 25px;
  cursor: move;
  z-index: 2;
  color: ${() => theme.grayScale.gray3};
`;

const StyledContainerWrapper = styled.div`
  width: 100%;
  border-top: ${props => `2px solid ${props.theme.grayScale.gray2}`};
  padding-top: 15px;
`;

const AdminFeaturesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const EditAdminFeaturesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledShowTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 16px;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledHideTagWrapper = styled('span')`
  margin-left: 16px;
  cursor: pointer;
`;

const StyledHideTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;
