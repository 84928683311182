import Constants from 'constants/index';
import {
  isAdminPath,
  isComplianceDocumentPath,
  isCompliancePath,
  isLeadershipPath,
  isReportsPath,
  isReportsPlansViewPath,
  isReportsTeamMembersViewPath,
  isSettingsPath,
  isTeamMemberPlan,
  isTrainingAssignedPath,
  isTrainingBuildQuizzesPath,
  isTrainingEditQuizzesPath,
  isTrainingModePath,
  isTrainingPath,
  isTrainingPlanPath,
  isTrainingTeamMemberProgressPath,
  isTrainingTeamMembersPath,
} from 'util/url';
import { useDeviceInfo } from 'util/device';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { Surface } from 'cfa-react-components';
import { useEffect, useRef, useState } from 'react';
import { hasDropShadow } from 'store/subheader/selectors';
import { useIsNative } from 'hooks/useIsNative';
import SearchbarSubheader from './SearchbarSubheader';
import AdminSubheader from './AdminSubheader';
import ReportsSubheader from './ReportsSubheader';
import TrainingSubheader from './TrainingSubheader';

const Subheader = () => {
  const location = useLocation();
  const subheaderRef = useRef(null);
  const { isDesktop } = useDeviceInfo();
  const isNative = useIsNative();
  const [hasChildren, setHasChildren] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const showDropShadow = useSelector(hasDropShadow);

  useEffect(() => {
    const updateScrollStatus = () => {
      const y = subheaderRef.current?.getBoundingClientRect().y;
      const isHidden =
        subheaderRef.current?.getBoundingClientRect().height === 0;

      if ((isDesktop && y === 0) || (isNative && y === 0)) {
        setIsScrolled(true);
      } else if (!isDesktop && !isNative && y === 58) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (isHidden) {
        setIsScrolled(false);
      }
    };

    window?.addEventListener('scroll', updateScrollStatus);

    return () => {
      window?.removeEventListener('scroll', updateScrollStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAtLeastTrainerLocations = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const isAtLeastTrainer = isAtLeastTrainerLocations.length > 0;

  const isTrainingSubheader =
    !isAtLeastTrainer || isTrainingAssignedPath(location)
      ? null
      : isTrainingPath(location) &&
        !isTrainingPlanPath(location) &&
        !isTrainingTeamMembersPath(location) &&
        !isTrainingTeamMemberProgressPath(location) &&
        !isTrainingModePath(location) &&
        !isTeamMemberPlan(location) &&
        !isTrainingBuildQuizzesPath(location) &&
        !isTrainingEditQuizzesPath(location);

  const isAdminSubheader = isAdminPath(location);

  const isReportsSubheader =
    isReportsPath(location) &&
    !isReportsPlansViewPath(location) &&
    !isReportsTeamMembersViewPath(location);

  const isTrainingModeSubheader = isTrainingModePath(location);

  const isSearchSubheader = !(
    isReportsPath(location) ||
    isSettingsPath(location) ||
    isTrainingPath(location) ||
    isLeadershipPath(location) ||
    isAdminPath(location) ||
    isCompliancePath(location)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // this adds padding if there is a subheader showing and removes if there is not
    if (subheaderRef?.current) {
      const children = Array.from(subheaderRef?.current?.children);
      children.length > 0 ? setHasChildren(true) : setHasChildren(false);
    }
  });

  const hideSubHeader =
    isLeadershipPath(location) || isComplianceDocumentPath(location);

  return (
    <>
      {!hideSubHeader && (
        <SubheaderContainer
          $isDesktop={isDesktop}
          $isNative={isNative}
          elevation={isScrolled && showDropShadow ? 16 : 0}
        >
          <SubheaderContent
            $hasChildren={hasChildren}
            $isTrainingMode={isTrainingModeSubheader}
            ref={subheaderRef}
          >
            {isSearchSubheader && <SearchbarSubheader />}
            {isTrainingSubheader && <TrainingSubheader activeTabIndex={0} />}
            {isAdminSubheader && <AdminSubheader />}
            {isReportsSubheader && <ReportsSubheader activeTabIndex={0} />}
          </SubheaderContent>
        </SubheaderContainer>
      )}
    </>
  );
};

const SubheaderContainer = styled(Surface)`
  position: sticky;
  top: ${({ $isDesktop, $isNative }) =>
    $isDesktop || $isNative ? '0' : Constants.HEIGHT.MOBILE_TOP_NAV};
  z-index: 3;
  width: 100%;
  overflow: visible;
  background-color: ${({ theme }) => theme.primaryPalette.white};
`;

const SubheaderContent = styled.div`
  display: ${({ $isTrainingMode }) => $isTrainingMode && 'flex'};
  justify-content: ${({ $isTrainingMode }) => $isTrainingMode && 'center'};
  transform: ${({ $isTrainingMode }) =>
    $isTrainingMode && 'translateY(-1.5em)'};
  margin: 0 auto;
  width: 100%;
  padding: ${({ $hasChildren }) => ($hasChildren ? '15px' : '0')};
  padding-bottom: 0;
  max-width: 1152px;
`;

export default Subheader;
