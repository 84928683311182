import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { createSlice } from '@reduxjs/toolkit';

const applyFilters = (plans, categoryFilters, searchFilter) => {
  let filteredPlans = plans;
  if (categoryFilters.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { category } = plan;
      const hasCategory = categoryFilters.includes(category);
      return hasCategory;
    });
  }
  if (searchFilter.length) {
    filteredPlans = filteredPlans.filter(plan => {
      const { name } = plan;
      const nameLocalLang = getNameFromLanguage(name);
      return nameLocalLang.toLowerCase().includes(searchFilter.toLowerCase());
    });
  }
  return filteredPlans;
};

const applySort = (plans, sort) => {
  let sortedPlans = plans;
  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedPlans = plans.toSorted((a, b) =>
        getNameFromLanguage(a.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(b.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedPlans = plans.toSorted((a, b) =>
        getNameFromLanguage(b.name)
          .toLowerCase()
          .localeCompare(getNameFromLanguage(a.name).toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.NEWEST:
      sortedPlans = plans.toSorted(
        (a, b) => new Date(b.created) - new Date(a.created),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.OLDEST:
      sortedPlans = plans.toSorted(
        (a, b) => new Date(a.created) - new Date(b.created),
      );
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedPlans;
};

const applyPagination = (plans, page, pageSize) => {
  const total = plans.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedPlans = plans.slice(0, showing);
  return { loadedPlans, showing, total };
};

export const slice = createSlice({
  name: 'buildPlansFilter',
  initialState: {
    categoryFilters: [],
    filteredPlans: [],
    page: 1,
    pageSize: 12,
    paginatedPlans: [],
    plans: [],
    searchFilter: '',
    showing: 0,
    sort: Constants.PLANS_SORT_OPTIONS.NEWEST,
    sortedPlans: [],
  },
  reducers: {
    setBuildPlans: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.plans = action.payload.plans;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    addBuildPlansCategoryFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.categoryFilters = [...state.categoryFilters, action.payload.filter];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setBuildPlansSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = action.payload.searchFilter;
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    setBuildPlansSort: (state, action) => {
      state.sort = action.payload.sort;
      state.sortedPlans = applySort(state.plans, state.sort);
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearBuildPlansCheckboxFilters: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.categoryFilters = [];
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    clearBuildPlansSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilter = '';
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    loadMorePlans: state => {
      state.page += 1;
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
    removeBuildPlansFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      const { filter } = action.payload;
      if (state.categoryFilters.includes(filter)) {
        state.categoryFilters = state.categoryFilters.filter(
          categoryFilter => categoryFilter !== filter,
        );
      }
      state.filteredPlans = applyFilters(
        state.sortedPlans,
        state.categoryFilters,
        state.searchFilter,
      );
      ({
        loadedPlans: state.paginatedPlans,
        showing: state.showing,
        total: state.total,
      } = applyPagination(state.filteredPlans, state.page, state.pageSize));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBuildPlans,
  addBuildPlansCategoryFilter,
  setBuildPlansSearchFilter,
  setBuildPlansSort,
  clearBuildPlansCheckboxFilters,
  clearBuildPlansSearchFilter,
  loadMorePlans,
  removeBuildPlansFilter,
} = slice.actions;

export default slice.reducer;
