import Constants from 'constants/index';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { uniq } from 'lodash/array';

export const selectReducerState = state => state.user;
/*
export const selectUser = createSelector(
  selectReducerState,
  reducerState => reducerState,
);*/

export const selectUserFullName = createSelector(
  selectReducerState,
  reducerState => reducerState.fullName,
);

export const selectUserType = createSelector(
  selectReducerState,
  reducerState => reducerState.userType,
);

export const selectUserId = createSelector(
  selectReducerState,
  reducerState => reducerState.userId,
);

export const selectUserPermissions = createSelector(
  selectReducerState,
  reducerState => reducerState.permissions,
);

export const selectUserIsStaff = createSelector(
  selectUserPermissions,
  userPermissions => {
    const locations = getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.LOGIN,
    );
    return locations?.includes('00000') ?? false;
  },
);

// This currently places admin last since its not a role in CFA stores
export const selectUsersHighestPermissionLevel = createSelector(
  selectReducerState,
  reducerState => {
    if ((reducerState.permissions?.ADMIN?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.ADMIN;
    } else if ((reducerState.permissions?.OPERATOR?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.OPERATOR;
    } else if ((reducerState.permissions?.LEADER?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.LEADER;
    } else if ((reducerState.permissions?.TRAINER?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.TRAINER;
    } else if ((reducerState.permissions?.LOGIN?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.LOGIN;
    }
  },
);

export const selectUserLanguage = createSelector(
  selectReducerState,
  reducerState => reducerState.language,
);

export const selectUserCountry = createSelector(
  selectReducerState,
  reducerState => reducerState.country,
);

export const selectUser = createSelector(selectReducerState, user => ({
  ...user,
}));

const getLocationsWithPermissionLevel = (userPermissions, permissionLevel) => {
  const permission = Object.keys(userPermissions).find(
    perm => perm === permissionLevel,
  );
  const locations = get(userPermissions, permission);
  return Array.isArray(locations) && locations.length ? locations : [];
};

export const selectLocationsWithOperatorPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.OPERATOR,
    ),
);

export const selectLocationsWithLeaderPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.LEADER,
    ),
);

export const selectLocationsWithTrainerPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.TRAINER,
    ),
);

export const selectLocationsWithLoginPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.LOGIN,
    ),
);

export const selectLocationsWithAdminPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.ADMIN,
    ),
);

// Okta perms are distinct for actual users so there should not be any cases where a user is an operator and leader
// for ex. However this selector is still used to most easily get a list of all locations a user has permissions
export const selectAllLocationsWithAtLeastLeaderPermissions = createSelector(
  selectLocationsWithLeaderPermission,
  selectLocationsWithOperatorPermission,
  (leaderPerms, operatorPerms) => uniq([...leaderPerms, ...operatorPerms]),
);

export const selectAllLocationsWithAtLeastTrainer = createSelector(
  selectLocationsWithLeaderPermission,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
  (leaderPerms, operatorPerms, trainerPerms) =>
    uniq([...leaderPerms, ...operatorPerms, ...trainerPerms]),
);

export const selectAllLocationsWithAllPermissions = createSelector(
  selectLocationsWithLeaderPermission,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
  (leaderPerms, operatorPerms, trainerPerms, loginPerms) =>
    uniq([...leaderPerms, ...operatorPerms, ...trainerPerms, ...loginPerms]),
);
